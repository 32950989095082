* {
  font-family: 'Poppins', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https:////db.onlinewebfonts.com/c/800e985f75a5a56c2f7b4be502d286de?family=Burbank+Big+Condensed');

@font-face {
  font-family: "Burbank Big Condensed";
  src: url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot");
  src: url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.eot?#iefix") format("embedded-opentype"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff2") format("woff2"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.woff") format("woff"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.ttf") format("truetype"), url("https://db.onlinewebfonts.com/t/800e985f75a5a56c2f7b4be502d286de.svg#Burbank Big Condensed") format("svg");
}

@media screen and (max-width: 499px) {
  .vid2{
    display: none;
  }
  .vid3{
    display: none;
  }
  .logo2{
    
  }
  .logo2 img{
    width: 80px;
    height: 80px;
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 35px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: min-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
   
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;
  }

  
.buy{
  font-size: 23px;
  cursor: pointer;
  border: 2px solid black;
  padding: 10px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
  margin-top: 20px;
}
  
  .tokenH2 {
    font-size: 15px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  }  
 
  .stadiumMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -150px;
    margin-bottom: 50px;
  }

  .chatbxMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    animation: floating 2s ease-in-out infinite;

  }

  .rmMobileDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10%;
   }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 30px;
    height: 30px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 20px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%; 
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;  
    margin-top: 30px;  
   }
  
  .missionMain img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 20px;
  }
  
  .missionCon div {
    color: white;
    font-size: 15px;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 2px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
   }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 45px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 30px;
  padding-right: 30px;
  margin-bottom: 5%;
  text-align: center;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
    text-align: center;
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #242424;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    width: 270px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 2px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 85px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
    display: none;
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
  }
  
  .introduction2 p {
    text-align: center;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 16px;
  }

  .layers{
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
  }
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 110px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 70px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 35px;
    height: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 20px;
    display: flex;
    border: none;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 60px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 90px;
    height: 90px;
  }
 
  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 18px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: cover;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 28px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 250px;
    height: 250px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 60%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 600px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .building1 {
    width: 18%;
    margin-right: 45%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon2{
    width: 16%;
    margin-right: 20%;
    margin-top: 33%;
    position: absolute;
    z-index: 1000;
    animation: floating 8s ease-in-out infinite;
  }
  
  .building1:hover+.q1 {
    cursor: pointer;
    margin-top: 15%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 60%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q1 {
    display: none;
  
  }
  
  .building2:hover+.q2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
    margin-top: -10%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q2 {
    display: none;
  
  }
  
  .building3:hover+.q3 {
    cursor: pointer;
    margin-top: 75%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 50%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q3 {
    display: none;
  
  }
  
  .building4:hover+.q4 {
    cursor: pointer;
    margin-top: 45%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 45%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q4 {
    display: none;
  
  }
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -60px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -30px;
    margin-right: -30px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 80%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 55px;
    height: 55px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 55px;
    height: 55px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 80%;
    margin-top: -32%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
  
  .flame {
    top: -40px;
    left: 150px;
    height: 10px;
    width: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #ffffff;
    animation: flame 0.3s linear infinite;
    z-index: -1;
  }
  
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;

  }
  
  .imgAndCon {
    transition: transform .5s;

  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 280px;
    height: 280px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 280px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
  }
  
  .imgAndConMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
  }
  
  .tokenV {
    width: 40%;
    position: sticky;
    bottom: 8%;
    left: 90%;
    display: none;
  }

  .tokenV2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
}

@media screen and (min-width: 500px) and (max-width: 767px) {
  .vid2{
    display: none;
  }
  .vid3{
    display: none;
  }
  .logo2{
    
  }
  .logo2 img{
    width: 80px;
    height: 80px;
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 35px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: min-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
   
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 10px;
  }
  
.buy{
  font-size: 25px;
  cursor: pointer;
  border: 2px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}


  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  }  
 
  .stadiumMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -150px;
    margin-bottom: 50px;
  }

  .chatbxMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    animation: floating 2s ease-in-out infinite;

  }

  .rmMobileDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10%;
   }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%; 
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 90%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;  
    margin-top: 30px;  
   }
  
  .missionMain img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 23px;
  }
  
  .missionCon div {
    color: white;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 2px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
   }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 45px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 120px;
    height: 120px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    width: 270px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 2px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 85px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
    display: none;
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
  }
  
  .introduction2 p {
    text-align: center;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 16px;
  }

  .layers{
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 6%;
  }
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 110px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 70px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 35px;
    height: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 20px;
    border: none;
    display: flex;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 16px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 90px;
    height: 90px;
  }
 
  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 18px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: cover;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 28px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 250px;
    height: 250px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 60%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 600px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .building1 {
    width: 18%;
    margin-right: 45%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon2{
    width: 16%;
    margin-right: 20%;
    margin-top: 33%;
    position: absolute;
    z-index: 1000;
    animation: floating 8s ease-in-out infinite;
  }
  
  .building1:hover+.q1 {
    cursor: pointer;
    margin-top: 15%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 60%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q1 {
    display: none;
  
  }
  
  .building2:hover+.q2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
    margin-top: -10%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q2 {
    display: none;
  
  }
  
  .building3:hover+.q3 {
    cursor: pointer;
    margin-top: 75%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 50%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q3 {
    display: none;
  
  }
  
  .building4:hover+.q4 {
    cursor: pointer;
    margin-top: 45%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 45%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q4 {
    display: none;
  
  }
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -60px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 85%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 55px;
    height: 55px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 55px;
    height: 55px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 85%;
    margin-top: -23%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
   
  .flame {
    top: -40px;
    left: 150px;
    height: 10px;
    width: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #ffffff;
    animation: flame 0.3s linear infinite;
    z-index: -1;
  }
  
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 5%;

  }
  
  .imgAndCon {
    transition: transform .5s;

  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 300px;
    height: 300px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 300px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
  }
  
  .imgAndConMain {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;

    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
  }
  
  .tokenV {
    width: 40%;
    position: sticky;
    bottom: 8%;
    left: 90%;
    display: none;
  }

  .tokenV2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 5%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .logo2{
    
  }
  .logo2 img{
    width: 100px;
    height: 100px;
  }
  .miniBarMain {
    padding-bottom: 10px;
    padding-top: 10px;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    background-color: #1f1f1fd2;
    display: flex;
    justify-content: space-between;
  }

  .miniBar {
    display: flex;
    justify-content: space-between;
  }

  .navbarMain {
    padding-right: 30px;
    z-index: 1000000000000000000;
  }

  .navbar {
    width: 35px;
    justify-content: flex-end;
    cursor: pointer;
    margin-left: auto;
    margin-top: 20px;
    margin-bottom: auto;
    display: flex;
    z-index: 1000000000000000000;
  }

  #fontSize2 {
    font-size: 18px;
    color: white;
    text-align: left;
    text-shadow: 0 0 10px #FFFFFF;
  }

  .littleNav div {
    text-align: left;
    padding: 30px;
    cursor: pointer;
    border-top: rgba(218, 165, 32, 0.685) 1px solid;
  }

  .littleNav {
    background-color: #1a1a1ad8;
    width: 100%;
    height: min-content;
    z-index: 1000000000;
    flex-flow: column wrap;
    display: flex;
    border-bottom: 5px rgba(49, 49, 49, 0.603) solid;
  }

  .hidden {
    display: none;
  }

  .close {
    width: 35px;
    height: 35px;
    justify-content: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    cursor: pointer;
    transition: transform .3s;
  }

  .navLink {
    color: #ff000091;
  }

  .littleNav img:hover {
    transform: scale(1.1);

  }
  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
   
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 19px;
    margin-bottom: 10px;
  }
  
.buy{
  font-size: 25px;
  cursor: pointer;
  border: 2px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}
  .tokenH2 {
    font-size: 16px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  }  
 
  .stadiumMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: -180px;
    margin-bottom: 50px;
  }

  .chatbxMob {
    width: 85%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    animation: floating 2s ease-in-out infinite;

  }

  .rmMobileDiv {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -10%;
   }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 25px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%; 
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 50%;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;  
    margin-top: 30px;  
   }
  
  .missionMain img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 23px;
  }
  
  .missionCon div {
    color: white;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 2px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 90%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
   }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 65px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 60px;
    height: 60px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 22px;
    width: 270px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 2px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 85px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
    display: none;
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
  }
  
  .introduction2 p {
    text-align: center;
    width: 80%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 17px;
  }
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 110px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }

  .layers{
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 100px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 35px;
    height: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 22px;
    display: flex;
    border: none;
    border-radius: 7px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 70px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 90px;
    height: 90px;
  }
 
  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 18px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 90%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 5rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 5rem;
    line-height: 5rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 28px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 250px;
    height: 250px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 60%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 400px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: none;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
  .building1 {
    width: 18%;
    margin-right: 45%;
    margin-top: 37%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon2{
    width: 16%;
    margin-right: 20%;
    margin-top: 33%;
    position: absolute;
    z-index: 1000;
    animation: floating 8s ease-in-out infinite;
  }
  
  .building1:hover+.q1 {
    cursor: pointer;
    margin-top: 15%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 60%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q1 {
    display: none;
  
  }
  
  .building2:hover+.q2 {
    cursor: pointer;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 60%;
    margin-top: -10%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q2 {
    display: none;
  
  }
  
  .building3:hover+.q3 {
    cursor: pointer;
    margin-top: 75%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-right: 50%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q3 {
    display: none;
  
  }
  
  .building4:hover+.q4 {
    cursor: pointer;
    margin-top: 45%;
    z-index: 10000;
    width: 30%;
    display: block;
    margin-left: 45%;
    opacity: 0;
    animation: popup 0.3s ease forwards;
  }
  
  @keyframes popup {
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .q4 {
    display: none;
  
  }
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 170px;
    height: 170px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 85%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 55px;
    height: 55px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 55px;
    height: 55px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 85%;
    margin-top: -20%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
    
  .flame {
    top: -40px;
    left: 150px;
    height: 10px;
    width: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #ffffff;
    animation: flame 0.3s linear infinite;
    z-index: -1;
  }
  
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;


  }
  
  .imgAndCon {
    transition: transform .5s;

  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 300px;
    height: 300px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 300px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
  }
  
  .imgAndConMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
  }
  
  .tokenV {
    width: 40%;
    position: sticky;
    bottom: 8%;
    left: 90%;
    display: none;
  }

  .tokenV2 {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
   }
}

@media screen and (min-width: 992px) and (max-width: 1199px) {
  .logo2{
    display: none;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  .tokenV2{
    display: none;
  }

  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
   
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
.buy{
  font-size: 25px;
  cursor: pointer;
  border: 2px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}
  .tokenH2 {
    font-size: 17px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 35px;
    height: 35px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 28px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-top: 1100px;
  
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 95%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 30%;
    text-align: center;
  }
  
  .missionMain img {
    width: 120px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 23px;
  }
  
  .missionCon div {
    color: white;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 2px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-right: auto;
    width: 45%;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 1px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 10px #01a5c2a8;
  
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 80px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 150px;
    height: 150px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
  }
  
  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 10px;
    margin-right: 10px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 70px;
    height: 70px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
  
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 25px;
    width: 300px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 2px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 85px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
  }
  
  .introduction2 p {
    text-align: center;
    width: 60%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 17px;
  }
  .layers{
    width: 50%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 3%;
  }
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 120px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 120px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 35px;
    height: 35px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 22px;
    border: none;
    border-radius: 7px;
    padding-left: 10px;
    display: flex;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 80px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 18px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 90px;
    height: 90px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 20px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 18px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 6rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 6rem;
    line-height: 6rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 30px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 300px;
    height: 300px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 60%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 500px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
.building1 {
  width: 18%;
  margin-right: 45%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon2{
  width: 16%;
  margin-right: 20%;
  margin-top: 33%;
  position: absolute;
  z-index: 1000;
  animation: floating 8s ease-in-out infinite;
}

.building1:hover+.q1 {
  cursor: pointer;
  margin-top: 35%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-right: 2%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q1 {
  display: none;

}

.building2:hover+.q2 {
  cursor: pointer;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 64%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q2 {
  display: none;

}

.building3:hover+.q3 {
  cursor: pointer;
  margin-top: 65%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 15%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q3 {
  display: none;

}

.building4:hover+.q4 {
  cursor: pointer;
  margin-top: 67%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 12%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q4 {
  display: none;

}
  
  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 200px;
    height: 200px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 65px;
    height: 65px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 65px;
    height: 65px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -15%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
    
  .flame {
    top: -40px;
    left: 150px;
    height: 10px;
    width: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #ffffff;
    animation: flame 0.3s linear infinite;
    z-index: -1;
  }
  
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  
  }
  
  .imgAndCon {
    transition: transform .5s;
  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 300px;
    height: 300px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 300px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
  }
  
  .imgAndConMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 6px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 18px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
  }
  
  .tokenV {
    width: 45%;
    position: sticky;
    bottom: 8%;
    left: 90%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1919px) {
  .logo2{
    display: none;
  }

  .tokenV2{
    display: none;
  }

.mintBtn:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.connectBtn:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

.littleNav {
  display: none;
}

.navbarMain {
  display: none;
}

.tokenHSub {
  text-shadow: 0 0 10px #ffd089;
  color: white;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}


.buy{
  font-size: 25px;
  cursor: pointer;
  border: 2px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.tokenH2 {
  font-size: 17px;
}

.mintBtn {
  background-color: #3aca75;
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 7px;
  border: none;

}

.connectBtn {
  background-color: #e64c43;
  color: white;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border: none;

}

.rmMobile {
  display: none;
}

.topLogo {
  display: none;
}

.special:hover {
  background-color: rgb(228, 194, 0);

}

.special:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.special2:hover {
  background-color: rgb(228, 194, 0);

}

.special2:active {
  background-color: rgb(228, 194, 0);
  box-shadow: -0px 7px rgb(153, 131, 4);
  transform: translateY(4px);
}

.placeAndWL {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 50px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  background: rgba(82, 82, 82, 0.2);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 30px;
}

.special {
  font-size: 20px;
  margin-left: 30px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.special2 {
  font-size: 20px;
  border-radius: 10px;
  background-color: gold;
  border: none;
  padding: 10px;
  box-shadow: -0px 5px rgb(194, 165, 5);
}

.mintingAmount {
  border: black 2px solid;
  border-radius: 10px;
  box-shadow: -0px 5px black;
  padding: 10px;
}

.connect2Mobile {
  display: none;
}

.discordNone {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: no-drop;
}

.discordNone:hover {
  transform: scale(1.1);
}

.linkedin {
  width: 30px;
  height: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 10px;
  cursor: pointer;

}

.team1 {
  width: 50%;
}

.discord3 {
  display: none;
}

.discord4 {
  display: none;
}

.storyHRmM {
  display: none;
}

.iconsMob {
  display: none;
}

.mainBanner {
  display: none;
}

.icons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-bottom: auto;
  margin-top: auto;
}

.cover52 {
  display: none;
}

.coverRm {
  width: 100vw;
}

.discordF2 {
  display: none;
}

.connectMobile {
  display: none;
}

.rightMobile {
  display: none;
}

a {
  color: rgb(255, 255, 255);
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: white;
}

.osPic {
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 10000;
}

.osPic2 {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.price {
  text-align: center;
  font-size: 28px;
  color: #acf9ff;
  font-weight: bold;
}

.discord2 {
  display: none;
}

.discord3 {
  padding-right: 20px;
  display: none;
}


.uRMobile {
  display: none;
}

.storyPicDivMobile {
  display: none;
}

.allWrap {
  background:
    url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  contain: content;
}

.boxWrap2 {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 10%;
  padding-bottom: 5%;
  position: relative;

}

.boxWrap2Mint {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
}

.boxWrap2Story {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: #000000;
  background:
    url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
}

.boxWrap2NFT {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: 
    url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
}

.boxWrap2TB {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
    url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
}

.boxWrap2Token {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background:
    url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
}


.videoContainer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  padding-top: 5%;
  padding-bottom: 5%;
}

.boxWrap2Team {
  display: flex;
  flex-flow: column nowrap;
  padding-bottom: 5%;
  margin-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  background-color: rgb(0, 0, 0);

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.boxWrap2Footer {
  display: flex;
  flex-flow: column nowrap;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  margin-top: 1500px;

}

.footer {
  background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
  padding-bottom: 5%;
  padding-top: 5%;
  padding-bottom: 100px;
  padding-top: 100px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.green {
  background-color: #97ba5e;

}

.about {
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  display: flex;
  flex-flow: row nowrap;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  width: 95%;
  padding: 15px;
  border-radius: 7px;
  background: rgb(130, 82, 4);
  background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
}

.missionMain {
  width: 30%;
  text-align: center;
}

.missionMain img {
  width: 120px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.missionCon p {
  color: white;
  font-size: 23px;
}

.missionCon div {
  color: white;
}

.about2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: column nowrap;
  width: 90%;
}

.tokenM {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  background: rgb(65,65,65);
background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
  width: 90%;
  border-radius: 20px;
  border: rgba(255, 255, 255, 0.527) 2px solid;
  margin-bottom: 5%;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 30px;
}

.tokenM2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 5%;
}

.tokenM img {
  width: 35%;
  height: 35%;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
}

.storyConT {
  color: white;
  text-align: justify;
  margin-right: auto;
  width: 45%;
}

.storyConT2 {
  color: white;
  text-align: justify;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
}

.tokenConDiv {
  margin-top: 8%;
  margin-bottom: 8%;
}

.tokenConDiv2 {
  margin-top: 8%;
  margin-bottom: 8%;
  background: rgb(3, 215, 250);
  background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border: 1px solid rgba(47, 206, 255, 0.541);
  box-shadow: 0 0 10px #01a5c2a8;

}

.aboutImg {
  width: 50%;
  border: white 6px solid;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-bottom: 3%;
}

.aboutImg2 {
  width: 28%;
  height: 28%;
  border: white 6px solid;
  transition: transform .5s;
}

.aboutImg2:hover {
  transform: scale(1.2);
}

.utilityPics {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
}

.roadmapMain1 {
  top: 0;
  position: relative;
}

.storyPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.UtilDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.storyH {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 80px;
  background: -webkit-linear-gradient(#01fffe, #08417a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  color: #ffffff;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  font-family: "Burbank Big Condensed";
  letter-spacing: 5px;
  -webkit-text-stroke: 2px rgb(255, 255, 255);
  border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
padding-left: 50px;
padding-right: 50px;
margin-bottom: 5%;

}

.storyH2 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  font-family: "Burbank Big Condensed";
}

.storyH6 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
  border: 3px solid white;
  border-bottom: 2px rgba(255, 255, 255, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 600px;
  font-family: "Burbank Big Condensed";

}

.storyH3 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
  border: 3px solid white;
  border-bottom: 2px rgba(255, 255, 255, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 600px;
  font-family: "Burbank Big Condensed";
}

.storyH4 {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  border-radius: 20px;
  width: 600px;
  font-family: "Burbank Big Condensed";
  background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
  border: 3px solid white;
}

.mintH {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  font-size: 60px;
  color: white;
  border-bottom: 2px rgba(255, 255, 255, 0);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 600px;
  font-family: "Burbank Big Condensed";
  background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
  border: 3px solid white;
  border-bottom: #00000000;
}

.storyRm img {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.tokenH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}


.carouselH {
  font-size: 70px;
  color: white;

  text-align: center;
  margin-bottom: 2%;
}

.rmH {
  font-size: 80px;
  color: white;

  text-align: center;
  background-color: #fe8a00;
  padding-left: 20px;
  margin-bottom: 20px;
}

.rmH2 {
  color: grey;
  font-size: 18px;
  line-height: 30px;
}

.rmName {
  background: rgb(81, 190, 229);
  background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
}



.storyCon {
  color: white;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;

}


.storyCon-2 {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding: 20px;
  border-radius: 50px;
  box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
  background-color: rgba(30, 46, 80, 0.568);

}

.utilCon {
  color: white;
  font-size: 20px;
  text-align: justify;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
}


.storyCon2 {
  font-size: 23px;
  text-align: center;
}

.storyConP {
  font-size: 20px;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.storyPic {
  width: 540px;
  height: 339px;
}

.storyRight {
  width: 45%;
  margin-top: auto;
  margin-bottom: auto;
  text-align: center;
}

.uR {
  width: 50%;
}

.intro {
  color: white;
  font-size: 100px;
  text-align: center;
}

.logoF {
  display: flex;
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.logoF img {
  width: 50%;
}

.intro img {
  width: 300px;
  height: 300px;
}

.intro3 {
  font-style: italic;
  font-size: 20px;
  font-weight: 500;
}

.intro2 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 50%;
  padding: 10px;
  border-radius: 50px;
}

.mintDiv {
  padding-top: 3%;
  padding-bottom: 3%;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
  margin-bottom: 80px;
  box-shadow: 0 0 10px #01a5c2;
  border: 2px solid #0080c8;
  border-radius: 20px;
  background-color: #0000006b;
}

.errorMessage {
  margin-left: 3%;
  margin-top: 1%;
  font-size: 18px;
  color: #fc4741;
  text-align: center;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
}

.loadingContainer {
  margin-top: 1%;
}

.loadingText {
  font-size: 22px;
  text-align: center;
  color: rgb(255, 255, 255);
  text-shadow: 0 0 10px #FFFFFF;
}

.loadTextSub {
  text-align: center;
  color: rgb(77, 77, 77);
  font-size: 13px;
  padding-top: 5px;
}

/* loading dots */


.loadingText:after {
  content: '.';
  animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {

  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  40% {
    color: rgb(151, 151, 151);
    text-shadow:
      .25em 0 0 rgba(0, 0, 0, 0),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  60% {
    text-shadow:
      .25em 0 0 rgb(124, 124, 124),
      .5em 0 0 rgba(0, 0, 0, 0);
  }

  80%,
  100% {
    text-shadow:
      .25em 0 0 rgb(121, 121, 121),
      .5em 0 0 rgb(121, 121, 121);
  }
}

.wpPic {
  width: 500px;
  height: 500px;
  margin-left: 10%;

}

.wPMobile {
  display: none;
}

.introductionMobile {
  display: none
}

.hl {
  color: #6ce34d;
}

.right {
  flex-flow: row nowrap;
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  margin-bottom: auto;
}

.right2 {
  flex-flow: row nowrap;
  display: flex;
  padding-bottom: 1.5%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.discord {
  transition: transform .2s;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.discord:hover {
  transform: scale(1.1);
}


/* width */
::-webkit-scrollbar {
  width: 10px;
  display: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #3b3b3b;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(99, 31, 177);
}



.note {
  color: rgb(255, 255, 255);
}

.twitterSoc {
  margin-top: 10px;
  margin-right: 5px;
  /* z-index: -2;*/
  margin-left: 5px;

}

.discordSoc {
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  /*  z-index: -2;*/

}

/*.website{
  margin-top: 10px;
  margin-left: 10px;
 /* z-index: -2;*/
/*color: #ffffffa2;
}*/
.soc {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;

}





.wallet {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 15px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  padding-left: 50px;
  padding-right: 50px;

  border: 2px solid rgb(73, 81, 190);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);


}

.wallet:hover {
  background-color: white;
  color: rgb(73, 81, 190);
}

.connect {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  font-family: "Burbank Big Condensed";
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
}

.connect2 {
  z-index: 1000;
  display: flex;
  flex-flow: row nowrap;
  margin-top: auto;
  margin-bottom: auto;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}

.connect div {
  margin-left: 10px;
  margin-right: 10px;
  font-family: "Burbank Big Condensed";
  color: #c9f4f7;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s ease;
  color: white;

}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(255, 219, 60);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
  }
}




.nftblockWalletConnectedALL {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding-bottom: 50px;
  margin-left: auto;
  margin-right: auto;

}

.minting_count_button {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.center {
  margin-top: auto;
  margin-bottom: auto;
}

.mintbuttondiv {
  text-align: center;
}

.nftblockWalletConnected {
  display: flex;
  margin-bottom: 10px;
}

.btnfos-0-2 {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 100px;
  z-index: 100000000;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.btnfos-0-2-2 {
  margin-top: 20px;
  font-size: 40px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 5px;
  width: 70px;
  height: 70px;
  border: none;
  border-radius: 100px;
  z-index: 100000000;

  cursor: pointer;
  background-color: #3a9ae8;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;

}


.btnfos-0-2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);

}

.btnfos-0-2-2:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);

}


.btnfos-0-3 {
  margin-top: 20px;
  color: hsl(0, 0%, 100%);
  background-color: #00000063;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 25px;
  width: 300px;
  transition: transform .2s;
  border: none;
  z-index: 100000000;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  border: 2px solid #38adec;
}

.currency{
  width: 35px;
  height: 35px;
  margin-left: 20px;
}

.btnfos-0-3:hover {

  color: white;
}

.nftamount {
  color: #000000;
  font-size: 85px;
  font-family: "Burbank Big Condensed";

  -webkit-text-stroke: 1px #a0dbfd;
  margin-top: 1%;
}

.walletConnect {

  display: flex;
  justify-content: center;
}

.connectButton {

  font-size: 45px;
}

.connectButton:hover {
  color: darkcyan;
  cursor: pointer;

}

.loadingContainer {
  text-align: center;
  padding-top: 20px;
}

.errorMessage {
  text-align: center;
  padding-top: 20px;
}

.successfully {
  text-align: center;
  padding-top: 20px;
  color: #1ee03e;

  font-size: 30px;
  text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
}

/*.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 95%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #ffffff;
  position: -webkit-sticky;
  position: sticky;
  top: 2%;
  z-index: 1000000;
  margin-top: 2%;
  border-radius: 100px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0px 5px #007997;
  border-right: #2d363600;
  border-top: #2d363600;

}*/

.headers {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #000000c2;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000000;

}

.h1 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/
  margin-top: auto;
  margin-bottom: auto;
}

.h2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  background-color: #5ebaad;

  /* border-left: #2d363600;
  border-right: #2d363600;
  border-top: #2d363600;*/

}

.introduction {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  margin-top: 3%;

}

.introduction2 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 3%;
  padding-top: 3%;
  background-color: #00ccff;
  padding-left: 5%;
  padding-right: 5%;
}

.layers{
  width: 40%;
}

.introduction2 p {
  text-align: center;
  width: 90%;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  color: rgb(0, 0, 0);
  font-size: 17px;
}

.introT1 {
  font-family: "Burbank Big Condensed";
  text-align: center;
  font-size: 120px;
  color: #ffffff;
  text-shadow: 0 0 10px #ffed9d;
}

.introT {
  font-family: "Burbank Big Condensed";
  text-align: center;
  font-size: 120px;
  color: #037896;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
}

.downArrowDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: 1%;
}

.downArrow {
  width: 35px;
  height: 35px;
  animation: arrow_down_animation 2s ease-in-out infinite;
}

.introduction2 button {
  width: fit-content;
  margin-left: auto;
  display: flex;
  margin-right: auto;
  background-color: white;
  border: none;
  font-size: 22px;
  border: none;
  border-radius: 7px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

@keyframes arrow_down_animation {

  0% {
    transform: translate3d(0, 0, 0) scale(1, 1);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
    opacity: 0;

  }

}

.wallet2 {
  z-index: 1000;
  background-color: #000000;
  color: #ffffff;
  padding: 13px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  border-radius: 50px;
  width: 270px;
  transition: transform .2s;
  border: none;
  font-family: "Gordita";
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
  transition: 0.5s ease;
  cursor: pointer;
  box-shadow: 0 3px #363636;
  letter-spacing: 2px;
}

.wallet2:hover {
  color: #000000;
  background-color: #ffffff;
}

.wallet2Btn {
  background-color: rgba(53, 52, 52, 0);
  border-style: solid;
  color: white;
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  border-radius: 25px;

  box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
  width: 400px;
  border: 2px solid #ffffff;
  margin-left: 10px;
  margin-right: 10px;
  border: 2px solid #2E8B57;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
}

.wallet2Btn:hover {
  color: rgb(156, 156, 156);
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}


.wallet3 {
  background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
  color: rgb(0, 0, 0);
  padding: 15px 25px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 30px;
  font-weight: bold;
  border-radius: 15px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 50px;
  padding-right: 50px;
  box-shadow: black 0px 5px;
}

.wallet3:hover {
  animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes slidebg {
  to {
    background-position: 20vw;
  }
}

.in2 {
  width: 100%;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  flex-flow: column nowrap;
}

.totalSupply {
  text-align: center;
  font-size: 80px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: rgb(0, 0, 0);
  font-family: "Burbank Big Condensed";

  -webkit-text-stroke: 1px #a0dbfd;
  margin-top: 1%;
}

@keyframes Slide_Up {
  0% {
    transform: translateY(250px);
    opacity: 1;
  }

  10%,
  87.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Tag {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  20%,
  92.5% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

@keyframes Slide_Up_Buttons {
  0% {
    transform: translateY(250px);
    opacity: 0;
  }

  10% {
    opacity: 0;
  }

  20% {
    opacity: 1;
  }

  25%,
  95% {
    transform: translateY(0);
  }

  90% {
    opacity: 1;
  }

  95% {
    opacity: 0;
  }

  100% {
    transform: translateY(250px);
    opacity: 0;
  }

}

.copyright {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 18px;
  text-shadow: 0 0 10px #FFFFFF;
  margin-top: 18px;
}

.btn3 {
  flex-flow: row nowrap;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.link {
  text-align: center;
  font-size: 15px;
  margin-top: 5px;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes Slide_Left {
  0% {
    transform: translateX(250px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes Slide_Left_Right {

  0%,
  100% {
    transform: translateX(100px);
  }

  12.5%,
  75% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

}

@keyframes Opacity {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }


}

@keyframes glow {
  from {
    box-shadow: 0 0 10px -10px rgb(127, 212, 48);
  }

  to {
    box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(.5, .5);
    opacity: .5;
  }

  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

@keyframes zoominoutsinglefeatured {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.05, 1.05);
  }

  100% {
    transform: scale(1, 1);
  }
}

.logo {
  width: 90px;
  height: 90px;
}

.logo:hover {
  animation: floating 2s ease-in-out infinite;

}

.logo2 {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

#fontSize {
  font-size: 18px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  z-index: 10000;
  color: #90d3ff;
}

.parrotDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;

}

.pic1 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  left: 70%;
  bottom: 0;

}

.pic2 {
  width: 32%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  left: -5%;
  bottom: 0;

}

/*.............. FAQ ..............*/



summary {
  font-size: 20px;
  background: rgba(19, 19, 19, 0.767);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  color: rgb(0, 0, 0);
  padding: 1rem;
  margin-bottom: 3rem;
  outline: none;
  text-align: left;
  cursor: pointer;
  border: #e2e2e2 2px ridge;
  box-shadow: 0 0 15px #704531;
  color: white;
  text-shadow: 0 0 10px #FFFFFF;
}

details>summary::-webkit-details-marker {
  display: none;
}

details[open] summary~* {
  animation: sweep .5s ease-in-out;
}

@keyframes sweep {
  0% {
    opacity: 0;
    margin-top: -10px
  }

  100% {
    opacity: 1;
    margin-top: 0px
  }
}

.accordin {
  width: 70%;
  color: white;
  display: inline-block;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.faq__content {
  color: rgb(255, 255, 255);
  padding-bottom: 20px;
  text-align: left;
  font-size: 18px;
  text-shadow: 0 0 10px #f8c9b3;

}

.faqTitle {
  margin-bottom: 20px;
  text-align: center;
  font-size: 70px;
  color: white;
}

.faqbg {
  width: 100%;
  text-align: left;
  padding-bottom: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 100%;
  padding-top: 5%;
  background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.cover {
  width: 100vw;
}

.cover2 {
  width: 100%;
}

.parrotPic {
  width: 100vw;
}

.parrot {
  width: 70%;
  border-radius: 30px;
}

.pDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
}

.rmMain {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: -100px;
}

.rmBox {
  text-align: right;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox2 {
  text-align: right;
  width: 80%;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px;
}

.rmBox {
  font-weight: 700;
  color: rgb(58, 58, 58);
  margin-bottom: 60%;
}

.year {
  font-size: 18px;

  color: rgb(8, 81, 94);
  letter-spacing: 1px;
}


.rmBoxMain2 {
  margin-top: 30%;
  width: 33.33%;
}

.rmBoxMain {
  width: max-content;
  justify-content: flex-start;
  width: 33.33%;

}

.rmBox2 {
  text-align: left;
  width: 30%;
}

.rmParrot {
  width: 130px;
  height: 186px;
  z-index: 1;
  justify-content: center;
  position: -webkit-sticky;
  position: sticky;
  top: 10%;
  margin-bottom: 25%;
  margin-right: auto;
  display: block;
  margin-left: 33%;
  margin-top: -50%;
}

.line {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 270vh;
  margin-top: -30%;
}

.rmP {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 33.33%;
  height: fit-content;
}

.box {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-top: -15%;
}

.discordF {
  padding-left: 10px;
  padding-right: 10px;
}

.socialAndLogo {
  flex-flow: row nowrap;
  justify-content: space-between;
  display: flex;
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 10%;
  padding-top: 10%;
}

.chartAndContent {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.tCon {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  color: white;
  font-weight: 500;
}

.tCon p {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  padding: 13px;
}

.chart {
  width: 60%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.boxWrapToken {
  display: flex;
  flex-flow: column nowrap;
  padding-top: 5%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  padding-left: 5%;
  padding-right: 5%;
  background: rgb(74, 175, 139);
  background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
}

.tH {
  color: #3b3b3b;
  font-weight: bold;
}

.cont {

  height: fit-content;
  background:
    url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.donutAndInfo {
  display: flex;
  flex-flow: row nowrap;
}

.donut {
  width: 45px;
  height: 45px;
  margin-right: 2%;
}

.rmCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  width: 90%;
}

.phrases p {
  font-size: 18px;
  letter-spacing: 2px;

}

.shoe {
  width: 400px;
  height: 400px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.phrases {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  flex-flow: row nowrap;
  width: fit-content;
  margin-top: 3%;
}

.rmCon1 {
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.teamCon {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  color: white;
  display: flex;
  flex-flow: column nowrap;

}

.teamCon img {
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  border: 3px solid white;
}

.memberDetails {
  color: white;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.memberName {
  font-size: 23px;
  text-align: center;
}

.memberCon {
  font-size: 20px;
  text-align: center;
}

@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

.ticker-wrap {
  width: 100%;
  overflow: hidden;
  height: 6rem;
  background-image: url('./assets/line.png');
  padding-left: 100%;
  background-size: contain;
}

.ticker-wrap .ticker {
  display: inline-block;
  height: 6rem;
  line-height: 6rem;
  white-space: nowrap;
  padding-right: 100%;
  box-sizing: content-box;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 60s;
}

.ticker-wrap .ticker__item {
  display: inline-block;
  padding: 0 1.5rem;
  font-size: 30px;
  color: #ffffff;
  font-family: "Burbank Big Condensed";
  letter-spacing: 2px;
}

.shark {
  width: 400px;
  height: 572px;
  z-index: 1;
  margin-right: -3%;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
}

.teamMain {
  display: flex;
}


.memName {

  color: #fe8f00;
  font-size: 23px;
  text-align: center;
  padding-right: 2%;

}

.memNamePosition {
  color: rgb(0, 0, 0);
  font-size: 17px;
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.man {
  width: 300px;
  height: 300px;
  margin-top: -1%;
  position: sticky;
  bottom: 0;
}


.tPicDiv {
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  display: flex;
}

.subT {

  color: #f9f305;
  font-size: 50px;
  text-align: left;
}

.manDiv {
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  display: flex;
  margin-bottom: 15px;
}

.memName2 {
  color: rgb(255, 255, 255);
  text-align: center;
  font-weight: 500;
  text-shadow: 0 0 10px rgb(255, 255, 255);
}

.rmDetails p {
  color: rgb(209, 209, 209);
  padding-left: 20px;
  font-size: 45px;
}

.flowers {
  width: 100%;
  margin-top: -10%;
}

.rmPicAndCon {
  display: flex;
  justify-content: flex-start;
  margin-left: 20px;
}

.footPrint2 {
  width: 60px;
  height: 60px;
}


/* Background */
.mainFoot {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  margin-top: -10%;
}

/* Footprint */
.footprint {
  position: absolute;
  top: 20%;
  left: 0;
  animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
  text-align: center;
}

/* Image Size */
.foot {
  max-width: 20%;
}

/* Right Footprint */
.rightFoot {
  margin: 5rem;
  animation-delay: 250ms;
}

/* Walking Animation */
@keyframes walk {
  to {
    transform: translateX(100vw);
  }
}

/* Opacity During Walking */
@keyframes walkopacity {

  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 0.4;
  }
}

/* Define the keyframes for the floating animation */
@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}


/* CSS */
.button-52 {
  font-size: 18px;
  font-family: "Burbank Big Condensed";
  letter-spacing: 1px;
  padding: 13px 20px 13px;
  outline: 0;
  border: 1px solid rgb(255, 255, 255);
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 250px;
  color: rgb(255, 255, 255);
  letter-spacing: 2px;
}

.button-52:after {
  content: "";
  background-color: #f37539;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 7px;
  left: 7px;
  transition: 0.2s;
}

.button-52:hover:after {
  top: 0px;
  left: 0px;
}

@media (min-width: 768px) {
  .button-52 {
    padding: 13px 50px 13px;
  }
}


.container video {
  width: 60%;

  height: 100%;
  object-fit: cover;
  -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
  transform-origin: center;
  transform: perspective(800px) rotateY(40deg);
  transition: 0.5s;
  box-shadow: 0 0 10px #01a5c2;
  border: 2px solid #0080c8;
}

.container {
  max-width: 500px;
  max-height: 380px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15%;
}

.container:hover video {
  opacity: 0.3;
}

.container video:hover {
  transform: perspective(800px) rotateY(0deg);
  opacity: 1;
}

/* Define the animation */
@keyframes bounce {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-100px);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

/* Apply the animation to an element */
#ball {
  width: 270px;
  height: 270px;
  animation: bounce 1.5s infinite;
  position: sticky;
  position: -webkit-sticky;
  left: 100%;
  bottom: 0px;
}

#ball3 {
  width: 270px;
  height: 270px;
  animation: bounce 1.5s infinite;
  position: sticky;
  position: -webkit-sticky;
  left: 5%;
  bottom: 0px;
}

.ball2 {
  width: 250px;
  height: 250px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  display: flex;
  justify-content: center;

  /* Animation to spin and move the sphere */
  -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;

  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  position: absolute;
  left: 0;
}

.ballDiv {
  position: relative;
  height: 300px;
  padding-top: 30px;
}

/* Spinning the sphere using key frames */
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg);
  }

  to {
    -ms-transform: rotate(360deg);
  }
}

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg);
  }

  to {
    -moz-transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

/* Move sphere from left to right */
@-moz-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@-ms-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@-webkit-keyframes moveLeftToRight {
  0% {
    left: -100px;
  }

  100% {
    left: 100%;
  }
}

@keyframes football-rolling {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


:root {
  --color1: #55476A;
  --color2: #AE3D63;
  --color3: #DB3853;
  --color4: #F45C44;
  --color5: #F8B663;
}

.container2 {
  min-height: 100vh;
  overflow: hidden;
  padding: 60px;
  position: relative;
  width: 100%;
}

.confetti {
  position: absolute;
  display: flex;
  width: 10px;
  height: 25px;
  top: -100px;
}

.confetti:nth-child(1) {
  background-color: var(--color1);
  animation: fall 2.5s linear infinite;
  left: 10%;
}

.confetti:nth-child(2) {
  background-color: var(--color2);
  animation: fall 2.3s linear infinite .2s;
  left: 20%;
}

.confetti:nth-child(3) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite .4s;
  left: 30%;
}

.confetti:nth-child(4) {
  background-color: var(--color4);
  animation: fall 2.7s linear infinite .1s;
  left: 40%;
}

.confetti:nth-child(5) {
  background-color: var(--color5);
  animation: fall 2.6s linear infinite .7s;
  left: 50%;
}

.confetti:nth-child(6) {
  background-color: var(--color1);
  animation: fall 2.4s linear infinite .2s;
  left: 60%;
}

.confetti:nth-child(7) {
  background-color: var(--color2);
  animation: fall 2.1s linear infinite .7s;
  left: 70%;
}

.confetti:nth-child(8) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite .9s;
  left: 80%;
}

.confetti:nth-child(9) {
  background-color: var(--color4);
  animation: fall 2.9s linear infinite .9s;
  left: 90%;
}

.confetti:nth-child(10) {
  background-color: var(--color5);
  animation: fall 2.2s linear infinite 1.1s;
  left: 100%;
}

.confetti:nth-child(11) {
  background-color: var(--color1);
  animation: fall2 2.5s linear infinite;
  left: 95%;
}

.confetti:nth-child(12) {
  background-color: var(--color2);
  animation: fall2 2.3s linear infinite 1.1s;
  left: 85%;
}

.confetti:nth-child(13) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite 1.2s;
  left: 75%;
}

.confetti:nth-child(14) {
  background-color: var(--color4);
  animation: fall2 2.7s linear infinite 1.3s;
  left: 65%;
}

.confetti:nth-child(15) {
  background-color: var(--color5);
  animation: fall2 2.6s linear infinite 1.4s;
  left: 55%;
}

.confetti:nth-child(16) {
  background-color: var(--color1);
  animation: fall2 2.4s linear infinite 1.5s;
  left: 45%;
}

.confetti:nth-child(17) {
  background-color: var(--color2);
  animation: fall2 2.1s linear infinite 1.6s;
  left: 35%;
}

.confetti:nth-child(18) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite 1.7s;
  left: 25%;
}

.confetti:nth-child(19) {
  background-color: var(--color4);
  animation: fall2 2.9s linear infinite 1.8s;
  left: 15%;
}

.confetti:nth-child(20) {
  background-color: var(--color5);
  animation: fall2 2.2s linear infinite 1.9s;
  left: 5%;
}

.confetti:nth-child(21) {
  background-color: var(--color1);
  animation: fall 2.5s linear infinite;
  left: 3%;
}

.confetti:nth-child(22) {
  background-color: var(--color2);
  animation: fall 2.3s linear infinite 1.3s;
  left: 13%;
}

.confetti:nth-child(23) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite 1.4s;
  left: 23%;
}

.confetti:nth-child(24) {
  background-color: var(--color4);
  animation: fall 2.7s linear infinite 1.5s;
  left: 33%;
}

.confetti:nth-child(25) {
  background-color: var(--color5);
  animation: fall 2.6s linear infinite 1.6s;
  left: 43%;
}

.confetti:nth-child(26) {
  background-color: var(--color1);
  animation: fall 2.4s linear infinite 1.2s;
  left: 53%;
}

.confetti:nth-child(27) {
  background-color: var(--color2);
  animation: fall 2.1s linear infinite 1.7s;
  left: 63%;
}

.confetti:nth-child(28) {
  background-color: var(--color3);
  animation: fall 2.4s linear infinite 1.8s;
  left: 73%;
}

.confetti:nth-child(29) {
  background-color: var(--color4);
  animation: fall 2.9s linear infinite 1.9s;
  left: 83%;
}

.confetti:nth-child(30) {
  background-color: var(--color5);
  animation: fall 2.2s linear infinite 1.1s;
  left: 93%;
}

.confetti:nth-child(31) {
  background-color: var(--color1);
  animation: fall2 2.2s linear infinite 1.1s;
  left: 7%;
}

.confetti:nth-child(32) {
  background-color: var(--color2);
  animation: fall2 2.9s linear infinite .9s;
  left: 17%;
}

.confetti:nth-child(33) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite .1s;
  left: 27%;
}

.confetti:nth-child(34) {
  background-color: var(--color4);
  animation: fall2 2.1s linear infinite .7s;
  left: 37%;
}

.confetti:nth-child(35) {
  background-color: var(--color5);
  animation: fall2 2.4s linear infinite .2s;
  left: 47%;
}

.confetti:nth-child(36) {
  background-color: var(--color1);
  animation: fall2 2.6s linear infinite .7s;
  left: 57%;
}

.confetti:nth-child(37) {
  background-color: var(--color2);
  animation: fall2 2.7s linear infinite .9s;
  left: 67%;
}

.confetti:nth-child(38) {
  background-color: var(--color3);
  animation: fall2 2.4s linear infinite .4s;
  left: 77%;
}

.confetti:nth-child(39) {
  background-color: var(--color4);
  animation: fall2 2.3s linear infinite .2s;
  left: 87%;
}

.confetti:nth-child(40) {
  background-color: var(--color5);
  animation: fall2 2.5s linear infinite .3s;
  left: 97%;
}

@keyframes fall {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(-90deg);
    opacity: 1;
  }

  100% {
    top: 900px;
    transform: rotate(360deg) rotateY(180deg);
    opacity: 0.7;
  }
}

@keyframes fall2 {
  0% {
    top: -110px;
    transform: rotate(0deg) rotateY(90deg);
    opacity: 1;
  }

  100% {
    top: 900px;
    transform: rotate(-360deg) rotateY(-180deg);
    opacity: 0.5;
  }
}

.paper {
  width: 100%;
}

.rmBg {
  position: relative;
  height: fit-content;
  display: flex;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.rmPicDiv {
  position: relative;
  flex-flow: column nowrap;
  background-color: #0000f6;
}

.rmPic {
  width: 90%;
   position: absolute;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 5%;
}

.buildings {
  width: 18%;
  z-index: 100000000000000000000;
}

.animateRm {
  transform: rotate(45deg);
  transition: transform 1s ease;
  /* Change the duration and easing to your liking */

}

.animateRm2 {
  transform: rotate(90deg);
  transition: transform 1s ease;
  /* Change the duration and easing to your liking */

}

.animateRm3 {
  transform: rotate(135deg);
  transition: transform 1s ease;
  /* Change the duration and easing to your liking */

}

.animateRm4 {
  transform: rotate(150deg);
  transition: transform 1s ease;
  /* Change the duration and easing to your liking */

}

.mapBg {

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;

  height: 190vh;
}

.building1 {
  width: 18%;
  margin-right: 45%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon2{
  width: 16%;
  margin-right: 20%;
  margin-top: 33%;
  position: absolute;
  z-index: 1000;
  animation: floating 8s ease-in-out infinite;
}

.building1:hover+.q1 {
  cursor: pointer;
  margin-top: 35%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-right: 2%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q1 {
  display: none;

}

.building2:hover+.q2 {
  cursor: pointer;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 64%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q2 {
  display: none;

}

.building3:hover+.q3 {
  cursor: pointer;
  margin-top: 65%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 15%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q3 {
  display: none;

}

.building4:hover+.q4 {
  cursor: pointer;
  margin-top: 67%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 8%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q4 {
  display: none;

}

.ball1-rm {
  width: 6%;
  margin-right: 26%;
  margin-top: 4%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.1s infinite;
}

.ball5-rm {
  width: 6%;
  margin-left: 65%;
  margin-top: 22%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.2s infinite;

}

@keyframes bounce2 {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-70px);
  }

  100% {
    transform: translateY(0) rotate(360deg);
  }
}

.baloon1{
  width: 16%;
  margin-left: -5%;
  margin-top: 2%;
  position: absolute;
  z-index: 1000;
  animation: floating 6s ease-in-out infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0);
  }
}

.building2 {
  width: 18%;
  margin-left: 18%;
  margin-top: 6%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon4{
  width: 16%;
  margin-left: -37%;
  margin-top: 60%;
  position: absolute;
  z-index: 1000;
  animation: floating 6s ease-in-out infinite;
}

.airB{
  width: 35%;
  margin-left: -37%;
  margin-top: 100%;
  position: absolute;
  z-index: 1000;
  transform: translateY(-50%);
  animation-name: balloon-float;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes balloon-float {
  0% {
    left: -50px;
  }
  50% {
    transform: translateY(-60%) rotate(5deg);
  }
  100% {
    left: 150%;
    transform: translateY(-50%) rotate(0deg);
  }
}

.building3 {
  width: 25%;
  margin-left: -60%;
  margin-top: 60%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.ball3-rm {
  width: 5%;
  margin-left: 72%;
  margin-top: 45%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 2s infinite;

}


.ball4-rm {
  width: 6%;
  margin-left: 72%;
  margin-top: 73%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball5-rm {
  width: 5%;
  margin-left: 15%;
  margin-top: 73%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1s infinite;

}

.ball6-rm {
  width: 4%;
  margin-right: 70%;
  margin-top: 56%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball9-rm {
  width: 4%;
  margin-right: -65%;
  margin-top: 5%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball7-rm {
  width: 5%;
  margin-left: 10%;
  margin-top: 28%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;

}

.ball8-rm {
  width: 4%;
  margin-left: 65%;
  margin-top: 18%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
  animation: bounce2 1.8s infinite;
}

.baloon3{
  width: 16%;
  margin-left: 30%;
  margin-top: 70%;
  position: absolute;
  z-index: 1000;
  animation: floating 5s ease-in-out infinite;
}

.building4 {
  width: 20%;
  margin-left: 45%;
  margin-top: 70%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.stadium5 {
  width: 18%;
  margin-right: 36%;
  margin-top: 68%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.stadium6 {
  width: 18%;
  margin-left: 55%;
  margin-top: 80%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.cup1 {
  width: 10%;
  margin-right: 70%;
  margin-top: 76%;
  position: absolute;
  z-index: 10000;
}

.shiny {
  box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 1000;
  margin-right: 67%;
  margin-top: 77%;
}

.shiny2 {
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 1000;
  margin-right: 67%;
  margin-top: 77%;
}

.shiny3 {
  box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
  /* Add a transition effect to the element */
  transition: box-shadow 0.5s;
  animation: glowing 1s infinite;
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 100%;
  position: absolute;
  z-index: 100;
  margin-left: 27.5%;
  margin-top: 5%;
}

/* CSS */
.button-51 {
  background-color: transparent;
  border: 1px solid #266DB6;
  box-sizing: border-box;
  color: #00132C;
  font-family: "Avenir Next LT W01 Bold", sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  padding: 16px 23px;
  position: relative;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-51:hover,
.button-51:active {
  outline: 0;
}

.button-51:hover {
  background-color: transparent;
  cursor: pointer;
}

.button-51:before {
  background-color: #D5EDF6;
  content: "";
  height: calc(100% + 3px);
  position: absolute;
  right: -7px;
  top: -9px;
  transition: background-color 300ms ease-in;
  width: 100%;
  z-index: -1;
}

.button-51:hover:before {
  background-color: #6DCFF6;
}

@media (min-width: 768px) {
  .button-51 {
    padding: 16px 32px;
  }
}

@keyframes glowing {

  /* Start the animation at 50% */
  50% {
    /* Increase the size and intensity of the shiny effect */
    box-shadow: 0 0 160px 5px rgb(255, 176, 4);
  }

  /* End the animation at 100% */
  100% {
    /* Return the element to its original state */
    box-shadow: 0 0 160px 2px rgb(255, 255, 255);
  }
}

.cup2 {
  width: 12%;
  margin-right: -21%;
  margin-top: 4%;
  position: absolute;
  z-index: 1000;
}

.partners {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: 50px;
}

.partnerDiv {
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  width: 70%;
  display: flex;
}

.partnerDiv img {
  width: 220px;
  height: 220px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  margin-top: -80px;
  opacity: 0.6;
  transition: 0.3s;
  cursor: pointer;
  margin-left: -40px;
  margin-right: -40px;
}

.partnerDiv img:hover {
  opacity: 1
}

/* CSS */
.button-78 {
  align-items: center;
  appearance: none;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: "Burbank Big Condensed";
  font-size: 30px;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;
  z-index: 0;
  width: 200px;
  letter-spacing: 3px;
  animation: floating 2s ease-in-out infinite;
}

.translate{
  position: sticky;
  bottom: 23%;
  margin-left: 90%;
   cursor: pointer;
  z-index: 1;
  z-index: 100000;
 
}

.translate img{
  width: 65px;
  height: 65px;
}

.translate:hover{
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  perspective: 1000px;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }
  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.stickyBtn {
  position: sticky;
  bottom: -7%;
  margin-left: -5%;
  margin-top: -30%;
  width: 400px;
  height: 400px;
  cursor: pointer;
  z-index: 1;
  animation: floating 2s ease-in-out infinite;
  z-index: 100000;
}

.stickyBtn2 {

  width: 65px;
  height: 65px;

}

#sticky-button {
  position: sticky;
  visibility: hidden;
  position: sticky;
  bottom: 7%;
  margin-left: 90%;
  margin-top: -12%;

  cursor: pointer;
  z-index: 1;
  z-index: 100000;
  transition: transform .4s;
}

.stickyBtn2:hover {
  transform: scale(1.1);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

@media (min-width: 768px) {
  .button-78 {
    padding: 19px 32px;
  }
}

.button-78:before,
.button-78:after {
  border-radius: 80px;
}

.button-78:before {
  background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  content: "";
  display: block;
  height: 100%;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -2;
}

.button-78:after {
  background-color: initial;
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  content: "";
  display: block;
  left: 4px;
  overflow: hidden;
  position: absolute;
  right: 4px;
  top: 4px;
  transition: all 100ms ease-out;
  z-index: -1;
}

.button-78:hover:not(:disabled):before {
  background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
}

.button-78:hover:not(:disabled):after {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  transition-timing-function: ease-in;
  opacity: 0;
}

.button-78:active:not(:disabled) {
  color: #ccc;
}

.button-78:active:not(:disabled):before {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
}

.button-78:active:not(:disabled):after {
  background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
  bottom: 4px;
  left: 4px;
  right: 4px;
  top: 4px;
}

.button-78:disabled {
  cursor: default;
  opacity: .24;
}

a {
  color: #83b2f8;
}

a:hover {
  color: #5f9fff;
  text-decoration: underline;
}


.flame {
  top: -40px;
  left: 150px;
  height: 10px;
  width: 60px;
  position: relative;
  border-radius: 50%;
  background-color: #ffffff;
  animation: flame 0.3s linear infinite;
  z-index: -1;
}

.flame2 {
  top: -195px;
  left: 115px;
}

@keyframes flame {
  0% {
    transform: translateX(0%);
  }

  50% {
    transform: translateX(50%);
  }

  100% {
    transform: translateX(0%);
  }
}

.video {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 10px;
}

.imgAndCon {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

}

.imgAndCon {
  transition: transform .5s;
}

.imgAndCon:hover {
  transform: scale(1.2);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.imgAndCon img {
  width: 400px;
  height: 400px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.imgAndCon div {
  color: rgb(255, 255, 255);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 400px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background: rgb(0, 46, 66);
  background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
}

.imgAndConMain {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
}

.selectMain{
  height: 100vh;
  width: 100vh;
  background-color: #000000;
}
.formMain1 {
  /*transform: translate(-50%, -50%);*/
  position: fixed;
  width: 100%;
  height: 100vh;
  /*top: 50%;*/
  /*left: 50%;*/
  z-index: 1000000000000000;
  background-color: rgba(24, 24, 26, 0.842);
}

.formMain {
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.856);
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 50%;
  left: 50%;
  z-index: 1000000000000000;
  overflow: scroll;
  overflow-x: hidden;
  border-radius: 10px;
  z-index: 100000;
  border: white 3px solid;
  display: flex;
  flex-flow: row nowrap;
}

.closeNote {
  position: absolute;
  right: 0;
  top: 1%;
  color: rgba(0, 0, 0, 0.836);
  font-size: 30px;
  font-weight: bold;
  background-color: rgba(172, 67, 67, 0);
  width: 100px;
  height: fit-content;
  cursor: pointer;
  border: none;
}

.closeNote:hover,
.closeNote:focus {
  color: rgb(0, 0, 0);
}

/* CSS */
.button-29 {
  align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s, transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow, transform;
  font-size: 18px;
}

.button-29:focus {
  box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
}

.button-29:hover {
  box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-29:active {
  box-shadow: #3c4fe0 0 3px 7px inset;
  transform: translateY(2px);
}

.tokenCon {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  text-align: center;
  color: rgba(0, 0, 0, 0.801);
  font-weight: bold;
  margin-top: 2%;
}

.tokenV {
  width: 45%;
  position: sticky;
  bottom: 8%;
  left: 90%;
}
}

@media screen and (min-width: 1920px) {
  .logo2{
    display: none;
  }

  .tokenV2{
    display: none;
  }

  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 30px;
    margin-bottom: 10px;
  }

  
.buy{
  font-size: 35px;
  cursor: pointer;
  border: 4px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}
  
  .tokenH2 {
    font-size: 26px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 45px;
    height: 45px;
    cursor: pointer;
    z-index: 10000;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 33px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-top: 2100px;
  
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 85%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 30%;
    text-align: center;
  }
  
  .missionMain img {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 32px;
  }
  
  .missionCon div {
    color: white;
    font-size: 22px;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 3px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-right: auto;
    width: 45%;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 2px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 15px #01a5c2a8;
  
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 110px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 220px;
    height: 220px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
   }

  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 40px;
    margin-left: 20px;
    margin-right: 20px;
    padding: 5px;
    width: 90px;
    height: 90px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 33px;
    width: 300px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 4px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 120px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
 

.layers{
  width: 40%;
}
  
  .introduction2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .introduction2 p {
    text-align: center;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 25px;
  }
  
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 170px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 170px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 45px;
    height: 45px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: white;
    border: none;
    font-size: 30px;
    border: none;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -4px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 110px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 22px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 140px;
    height: 140px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 24px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 25px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 24px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 7rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 7rem;
    line-height: 7rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 40px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 400px;
    height: 400px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 80%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 500px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
.building1 {
  width: 18%;
  margin-right: 45%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon2{
  width: 16%;
  margin-right: 20%;
  margin-top: 33%;
  position: absolute;
  z-index: 1000;
  animation: floating 8s ease-in-out infinite;
}

.building1:hover+.q1 {
  cursor: pointer;
  margin-top: 35%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-right: 2%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q1 {
  display: none;

}

.building2:hover+.q2 {
  cursor: pointer;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 64%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q2 {
  display: none;

}

.building3:hover+.q3 {
  cursor: pointer;
  margin-top: 65%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 15%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q3 {
  display: none;

}

.building4:hover+.q4 {
  cursor: pointer;
  margin-top: 67%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 8%;;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q4 {
  display: none;

}

  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 260px;
    height: 260px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 90px;
    height: 90px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 90px;
    height: 90px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -12%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
 
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  
  }
  
  .imgAndCon {
    transition: transform .5s;
  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 600px;
    height: 600px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 600px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
    font-size: 23px;
  }
  
  .imgAndConMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -4px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 70px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 24px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
    font-size: 24px;
  }
  
  .tokenV {
    width: 45%;
    position: sticky;
    bottom: 8%;
    left: 90%;
  }
}

@media screen and (min-width: 1921px) and (max-width: 3839px) {
  .logo2{
    display: none;
  }

  .tokenV2{
    display: none;
  }

  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 40px;
    margin-bottom: 10px;
  }

  
.buy{
  font-size: 45px;
  cursor: pointer;
  border: 5px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}
  
  .tokenH2 {
    font-size: 35px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 60px;
    height: 60px;
    cursor: pointer;
    z-index: 10000;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 42px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-top: 2800px;
  
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 85%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 30%;
    text-align: center;
  }
  
  .missionMain img {
    width: 210px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 43px;
  }
  
  .missionCon div {
    color: white;
    font-size: 28px;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 3px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-right: auto;
    width: 45%;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 40px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border: 5px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 15px #01a5c2a8;
  
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 140px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 260px;
    height: 260px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
   }

  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 3px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 15px;
    margin-right: 15px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 110px;
    height: 110px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 50px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 110px;
    height: 110px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 50px;
    width: 350px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 4px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 150px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .introduction2 p {
    text-align: center;
    width: 90%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 32px;
  }
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 250px;
    color: #ffffff;
    text-shadow: 0 0 10px #ffed9d;
  }
  .layers{
    width: 40%;
  }
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 200px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 55px;
    height: 55px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 40px;
    border: none;
    display: flex;
    border-radius: 7px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -5px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 150px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 30px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 170px;
    height: 170px;
  }

  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 35px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 30px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 1.5rem;
    margin-bottom: 3rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 30px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 9rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 9rem;
    line-height: 9rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 1.5rem;
    font-size: 50px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 550px;
    height: 550px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 90%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(40deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 600px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
.building1 {
  width: 18%;
  margin-right: 45%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon2{
  width: 16%;
  margin-right: 20%;
  margin-top: 33%;
  position: absolute;
  z-index: 1000;
  animation: floating 8s ease-in-out infinite;
}

.building1:hover+.q1 {
  cursor: pointer;
  margin-top: 35%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-right: 2%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q1 {
  display: none;

}

.building2:hover+.q2 {
  cursor: pointer;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 64%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q2 {
  display: none;

}

.building3:hover+.q3 {
  cursor: pointer;
  margin-top: 65%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 15%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q3 {
  display: none;

}

.building4:hover+.q4 {
  cursor: pointer;
  margin-top: 67%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 8%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q4 {
  display: none;

}

  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 310px;
    height: 310px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 110px;
    height: 110px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 110px;
    height: 110px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -12%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }
  
  .flame {
    top: -40px;
    left: 150px;
    height: 10px;
    width: 60px;
    position: relative;
    border-radius: 50%;
    background-color: #ffffff;
    animation: flame 0.3s linear infinite;
    z-index: -1;
  }
  
  .flame2 {
    top: -195px;
    left: 115px;
  }
  
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  
  }
  
  .imgAndCon {
    transition: transform .5s;
  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 800px;
    height: 800px;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    width: 800px;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
    font-size: 30px;
  }
  
  .imgAndConMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 8px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -5px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 80px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 35px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
    font-size: 30px;
  }
  
  .tokenV {
    width: 45%;
    position: sticky;
    bottom: 8%;
    left: 90%;
  }
}

@media screen and (min-width: 3840px) {
  .logo2{
    display: none;
  }

  .tokenV2{
    display: none;
  }

  .mintBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .connectBtn:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  .littleNav {
    display: none;
  }
  
  .navbarMain {
    display: none;
  }
  
  .tokenHSub {
    text-shadow: 0 0 10px #ffd089;
    color: white;
    font-weight: bold;
    font-size: 60px;
    margin-bottom: 10px;
    
  }

  
.buy{
  font-size: 65px;
  cursor: pointer;
  border: 5px solid black;
  padding: 20px;
  background-color: #24242494;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 5px 12px;
}
  
  .tokenH2 {
    font-size: 50px;
  }
  
  .mintBtn {
    background-color: #3aca75;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 7px;
    border: none;
  
  }
  
  .connectBtn {
    background-color: #e64c43;
    color: white;
    border-radius: 5px;
    padding: 10px;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    border: none;
  
  }
  
  .rmMobile {
    display: none;
  }
  
  .topLogo {
    display: none;
  }
  
  .special:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .special2:hover {
    background-color: rgb(228, 194, 0);
  
  }
  
  .special2:active {
    background-color: rgb(228, 194, 0);
    box-shadow: -0px 7px rgb(153, 131, 4);
    transform: translateY(4px);
  }
  
  .placeAndWL {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 50px;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    background: rgba(82, 82, 82, 0.2);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 30px;
  }
  
  .special {
    font-size: 20px;
    margin-left: 30px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .special2 {
    font-size: 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    padding: 10px;
    box-shadow: -0px 5px rgb(194, 165, 5);
  }
  
  .mintingAmount {
    border: black 2px solid;
    border-radius: 10px;
    box-shadow: -0px 5px black;
    padding: 10px;
  }
  
  .connect2Mobile {
    display: none;
  }
  
  .discordNone {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: no-drop;
  }
  
  .discordNone:hover {
    transform: scale(1.1);
  }
  
  .linkedin {
    width: 30px;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    cursor: pointer;
  
  }
  
  .team1 {
    width: 50%;
  }
  
  .discord3 {
    display: none;
  }
  
  .discord4 {
    display: none;
  }
  
  .storyHRmM {
    display: none;
  }
  
  .iconsMob {
    display: none;
  }
  
  .mainBanner {
    display: none;
  }
  
  .icons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-bottom: auto;
    margin-top: auto;
  }
  
  .cover52 {
    display: none;
  }
  
  .coverRm {
    width: 100vw;
  }
  
  .discordF2 {
    display: none;
  }
  
  .connectMobile {
    display: none;
  }
  
  .rightMobile {
    display: none;
  }
  
  a {
    color: rgb(255, 255, 255);
    font-size: 20px;
    cursor: pointer;
    text-decoration: none;
  }
  
  a:hover {
    color: white;
  }
  
  .osPic {
    width: 80px;
    height: 80px;
    cursor: pointer;
    z-index: 10000;
    margin-left: 10px;
    margin-right: 10px;
  }
  
  .osPic2 {
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
  
  .price {
    text-align: center;
    font-size: 58px;
    color: #acf9ff;
    font-weight: bold;
  }
  
  .discord2 {
    display: none;
  }
  
  .discord3 {
    padding-right: 20px;
    display: none;
  }
  
  
  .uRMobile {
    display: none;
  }
  
  .storyPicDivMobile {
    display: none;
  }
  
  .allWrap {
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    contain: content;
  }
  
  .boxWrap2 {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 10%;
    padding-bottom: 5%;
    position: relative;
  
  }
  
  .boxWrap2Mint {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://media.discordapp.net/attachments/1008571037862080542/1103547155752419409/NFTHouse9_airplane_fliying_around_eiffel_tower_sunny_day_beauti_99cd7cfd-6912-4f4a-b8c0-ec897b9a2bae.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Story {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: #000000;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2NFT {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: 
      url('https://media.discordapp.net/attachments/1008571037862080542/1103560177849999380/NFTHouse9_qr_code_post_near_eiffel_tower_cartoon_theme_sunny_ba_dededb00-5b3f-48e7-ad0a-48fa9492d1a2.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2TB {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: radial-gradient(circle, rgba(8, 8, 8, 0.2587196264374122) 0%, rgba(0, 0, 0, 0.4244499635160639) 47%, rgba(0, 0, 0, 0.6828769298081987) 100%),
      url('https://media.discordapp.net/attachments/1008571037862080542/1103553976542167082/NFTHouse9_digital_eiffel_tower_cartoon_64k_dc21623f-a545-4c29-a01f-e7ee23ca7b11.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  .boxWrap2Token {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103558131868516413/NFTHouse9_eiffel_tower_made_with_coins_cartoon_theme_sunny_back_ab937628-e4a7-451c-b1c8-c8d5daa4c7bc.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
  }
  
  
  .videoContainer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background: url('https://cdn.midjourney.com/0c2b72a9-ce3e-4011-af03-6c8f7af0aa75/0_2.png') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding-top: 5%;
    padding-bottom: 5%;
  }
  
  .boxWrap2Team {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 5%;
    margin-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    background-color: rgb(0, 0, 0);
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .boxWrap2Footer {
    display: flex;
    flex-flow: column nowrap;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    margin-top: 4200px;
  
  }
  
  .footer {
    background: radial-gradient(circle, rgba(0, 0, 0, 0.452) 1%, rgba(0, 0, 0, 0.774) 49%, rgb(0, 0, 0) 100%), url('https://media.discordapp.net/attachments/1008571037862080542/1103059962864881834/NFTHouse9_digital_eiffel_tower_cartoon_technology_64k_29e51e81-1ad6-4e95-b303-ab1cf059deb2.png?width=746&height=418') no-repeat center center fixed;
    padding-bottom: 5%;
    padding-top: 5%;
    padding-bottom: 100px;
    padding-top: 100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .green {
    background-color: #97ba5e;
  
  }
  
  .about {
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    display: flex;
    flex-flow: row nowrap;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    width: 85%;
    padding: 15px;
    border-radius: 7px;
    background: rgb(130, 82, 4);
    background: linear-gradient(101deg, rgba(130, 82, 4, 0.2665441176470589) 0%, rgba(19, 82, 92, 0.26934523809523814) 53%, rgba(19, 82, 92, 0.40940126050420167) 100%);
  }
  
  .missionMain {
    width: 30%;
    text-align: center;
  }
  
  .missionMain img {
    width: 330px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }
  
  .missionCon p {
    color: white;
    font-size: 60px;
  }
  
  .missionCon div {
    color: white;
    font-size: 42px;
    margin-top: 20px;
  }
  
  .about2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: column nowrap;
    width: 90%;
  }
  
  .tokenM {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    background: rgb(65,65,65);
  background: linear-gradient(101deg, rgba(65,65,65,0.6306897759103641) 0%, rgba(84,81,81,0.5970763305322129) 53%, rgba(57,57,57,0.35898109243697474) 100%);
    width: 90%;
    border-radius: 20px;
    border: rgba(255, 255, 255, 0.527) 3px solid;
    margin-bottom: 5%;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 30px;
  }
  
  .tokenM2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 5%;
  }
  
  .tokenM img {
    width: 35%;
    height: 35%;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storyConT {
    color: white;
    text-align: justify;
    margin-right: auto;
    width: 45%;
  }
  
  .storyConT2 {
    color: white;
    text-align: justify;
    margin-right: auto;
    margin-left: auto;
    width: 50%;
  }
  
  .tokenConDiv {
    margin-top: 8%;
    margin-bottom: 8%;
  }
  
  .tokenConDiv2 {
    margin-top: 8%;
    margin-bottom: 8%;
    background: rgb(3, 215, 250);
    background: linear-gradient(101deg, rgba(3, 215, 250, 0.0592814241902212) 0%, rgba(0, 161, 187, 0.26934523809523814) 53%, rgba(40, 71, 136, 0.1379331095834796) 100%);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 50px;
    border-top-right-radius: 70px;
    border-bottom-left-radius: 70px;
    border: 5px solid rgba(47, 206, 255, 0.541);
    box-shadow: 0 0 15px #01a5c2a8;
  
  }
  
  .aboutImg {
    width: 50%;
    border: white 6px solid;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-bottom: 3%;
  }
  
  .aboutImg2 {
    width: 28%;
    height: 28%;
    border: white 6px solid;
    transition: transform .5s;
  }
  
  .aboutImg2:hover {
    transform: scale(1.2);
  }
  
  .utilityPics {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
  }
  
  .roadmapMain1 {
    top: 0;
    position: relative;
  }
  
  .storyPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .UtilDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .storyH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 220px;
    background: -webkit-linear-gradient(#01fffe, #08417a);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    color: #ffffff;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
    font-family: "Burbank Big Condensed";
    letter-spacing: 5px;
    -webkit-text-stroke: 2px rgb(255, 255, 255);
    border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  padding-left: 50px;
  padding-right: 50px;
  margin-bottom: 5%;
  
  }
  
  .storyH2 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH6 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #428800, #4da000, #5ab900, #5dc000, #6adb00);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  
  }
  
  .storyH3 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    background-image: linear-gradient(to right top, #f90000, #f63600, #f24e00, #ef6005, #eb6f12);
    border: 3px solid white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
  }
  
  .storyH4 {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #0038f9, #0088ff, #00b3ff, #00d3b1, #12eb52);
    border: 3px solid white;
  }
  
  .mintH {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    font-size: 60px;
    color: white;
    border-bottom: 2px rgba(255, 255, 255, 0);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 600px;
    font-family: "Burbank Big Condensed";
    background-image: linear-gradient(to right top, #6ca6da, #5a9ddb, #4793dc, #338adc, #1a80dc);
    border: 3px solid white;
    border-bottom: #00000000;
  }
  
  .storyRm img {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .tokenH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  
  .carouselH {
    font-size: 70px;
    color: white;
  
    text-align: center;
    margin-bottom: 2%;
  }
  
  .rmH {
    font-size: 80px;
    color: white;
  
    text-align: center;
    background-color: #fe8a00;
    padding-left: 20px;
    margin-bottom: 20px;
  }
  
  .rmH2 {
    color: grey;
    font-size: 18px;
    line-height: 30px;
  }
  
  .rmName {
    background: rgb(81, 190, 229);
    background: linear-gradient(0deg, rgba(81, 190, 229, 1) 2%, rgba(81, 190, 229, 1) 20%, rgba(6, 136, 198, 1) 63%, rgba(6, 136, 198, 1) 100%);
  }
  
  
  
  .storyCon {
    color: white;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  
  .storyCon-2 {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    padding: 20px;
    border-radius: 50px;
    box-shadow: -10px 10px rgba(14, 21, 36, 0.363);
    background-color: rgba(30, 46, 80, 0.568);
  
  }
  
  .utilCon {
    color: white;
    font-size: 20px;
    text-align: justify;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
  }
  
  
  .storyCon2 {
    font-size: 23px;
    text-align: center;
  }
  
  .storyConP {
    font-size: 20px;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
  }
  
  .storyPic {
    width: 540px;
    height: 339px;
  }
  
  .storyRight {
    width: 45%;
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
  
  .uR {
    width: 50%;
  }
  
  .intro {
    color: white;
    font-size: 100px;
    text-align: center;
  }
  
  .logoF {
    display: flex;
    width: 290px;
    height: 290px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
   }

  .logoF img {
    width: 50%;
  }
  
  .intro img {
    width: 300px;
    height: 300px;
  }
  
  .intro3 {
    font-style: italic;
    font-size: 20px;
    font-weight: 500;
  }
  
  .intro2 {
    color: rgb(0, 0, 0);
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 50%;
    padding: 10px;
    border-radius: 50px;
  }
  
  .mintDiv {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.493) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
    margin-bottom: 80px;
    box-shadow: 0 0 10px #01a5c2;
    border: 3px solid #0080c8;
    border-radius: 20px;
    background-color: #0000006b;
  }
  
  .errorMessage {
    margin-left: 3%;
    margin-top: 1%;
    font-size: 18px;
    color: #fc4741;
    text-align: center;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  .loadingContainer {
    margin-top: 1%;
  }
  
  .loadingText {
    font-size: 22px;
    text-align: center;
    color: rgb(255, 255, 255);
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  .loadTextSub {
    text-align: center;
    color: rgb(77, 77, 77);
    font-size: 13px;
    padding-top: 5px;
  }
  
  /* loading dots */
  
  
  .loadingText:after {
    content: '.';
    animation: dots 1s steps(5, end) infinite;
  }
  
  @keyframes dots {
  
    0%,
    20% {
      color: rgba(0, 0, 0, 0);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    40% {
      color: rgb(151, 151, 151);
      text-shadow:
        .25em 0 0 rgba(0, 0, 0, 0),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    60% {
      text-shadow:
        .25em 0 0 rgb(124, 124, 124),
        .5em 0 0 rgba(0, 0, 0, 0);
    }
  
    80%,
    100% {
      text-shadow:
        .25em 0 0 rgb(121, 121, 121),
        .5em 0 0 rgb(121, 121, 121);
    }
  }
  
  .wpPic {
    width: 500px;
    height: 500px;
    margin-left: 10%;
  
  }
  
  .wPMobile {
    display: none;
  }
  
  .introductionMobile {
    display: none
  }
  
  .hl {
    color: #6ce34d;
  }
  
  .right {
    flex-flow: row nowrap;
    display: flex;
    justify-content: space-evenly;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .right2 {
    flex-flow: row nowrap;
    display: flex;
    padding-bottom: 1.5%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .discord {
    transition: transform .2s;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .discord:hover {
    transform: scale(1.1);
  }
  
  
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    display: none;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #3b3b3b;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(99, 31, 177);
  }
  
  
  
  .note {
    color: rgb(255, 255, 255);
  }
  
  .twitterSoc {
    margin-top: 10px;
    margin-right: 5px;
    /* z-index: -2;*/
    margin-left: 5px;
  
  }
  
  .discordSoc {
    margin-top: 10px;
    margin-right: 5px;
    margin-left: 5px;
    /*  z-index: -2;*/
  
  }
  
  /*.website{
    margin-top: 10px;
    margin-left: 10px;
   /* z-index: -2;*/
  /*color: #ffffffa2;
  }*/
  .soc {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
  
  }
  
  
  
  
  
  .wallet {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    padding-left: 50px;
    padding-right: 50px;
  
    border: 2px solid rgb(73, 81, 190);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgba(82, 168, 236, 0.6);
  
  
  }
  
  .wallet:hover {
    background-color: white;
    color: rgb(73, 81, 190);
  }
  
  .connect {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    font-family: "Burbank Big Condensed";
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
  }
  
  .connect2 {
    z-index: 1000;
    display: flex;
    flex-flow: row nowrap;
    margin-top: auto;
    margin-bottom: auto;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .connect div {
    margin-left: 28px;
    margin-right: 28px;
    font-family: "Burbank Big Condensed";
    color: #c9f4f7;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    cursor: pointer;
    transition: 0.5s ease;
    color: white;
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(255, 219, 60);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(255, 219, 60, 0.233);
    }
  }
  
  
  
  
  .nftblockWalletConnectedALL {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
  
  }
  
  .minting_count_button {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .center {
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .mintbuttondiv {
    text-align: center;
  }
  
  .nftblockWalletConnected {
    display: flex;
    margin-bottom: 10px;
  }
  
  .btnfos-0-2 {
    font-size: 60px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 160px;
    height: 160px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .btnfos-0-2-2 {
    margin-top: 20px;
    font-size: 60px;
    margin-left: 30px;
    margin-right: 30px;
    padding: 5px;
    width: 160px;
    height: 160px;
    border: none;
    border-radius: 100px;
    z-index: 100000000;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;

    cursor: pointer;
    background-color: #3a9ae8;
    color: white;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  
  }
  
  
  .btnfos-0-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  .btnfos-0-2-2:hover {
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  
  }
  
  
  .btnfos-0-3 {
    margin-top: 20px;
    color: hsl(0, 0%, 100%);
    background-color: #00000063;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 80px;
    width: 550px;
    transition: transform .2s;
    border: none;
    z-index: 100000000;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    border: 6px solid #38adec;
  }
  
  .currency{
    width: 35px;
    height: 35px;
    margin-left: 20px;
  }
  
  .btnfos-0-3:hover {
  
    color: white;
  }
  
  .nftamount {
    color: #000000;
    font-size: 210px;
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  .walletConnect {
  
    display: flex;
    justify-content: center;
  }
  
  .connectButton {
  
    font-size: 45px;
  }
  
  .connectButton:hover {
    color: darkcyan;
    cursor: pointer;
  
  }
  
  .loadingContainer {
    text-align: center;
    padding-top: 20px;
  }
  
  .errorMessage {
    text-align: center;
    padding-top: 20px;
  }
  
  .successfully {
    text-align: center;
    padding-top: 20px;
    color: #1ee03e;
  
    font-size: 30px;
    text-shadow: -1px -1px 1px rgba(255, 255, 255, .1), 1px 1px 1px rgba(0, 0, 0, .5);
  }
  
  /*.headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 95%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 2%;
    z-index: 1000000;
    margin-top: 2%;
    border-radius: 100px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 5px #007997;
    border-right: #2d363600;
    border-top: #2d363600;
  
  }*/
  
  .headers {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #000000c2;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000000;
  
  }
  
  .h1 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
    margin-top: auto;
    margin-bottom: auto;
  }
  
  .h2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background-color: #5ebaad;
  
    /* border-left: #2d363600;
    border-right: #2d363600;
    border-top: #2d363600;*/
  
  }
  
  .introduction {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    width: 100%;
    margin-top: 3%;
  
  }
  
  .introduction2 {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: auto;
    padding-bottom: 3%;
    padding-top: 3%;
    background-color: #00ccff;
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .introduction2 p {
    text-align: center;
    width: 70%;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    color: rgb(0, 0, 0);
    font-size: 48px;
  }
  
  .introT1 {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 350px;
    color: #ffffff;
    text-shadow: 0 0 30px #ffed9d;
  }
  .layers{
    width: 50%;
  }
  
  .introT {
    font-family: "Burbank Big Condensed";
    text-align: center;
    font-size: 290px;
    color: #037896;
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.144), 0px -5px 35px rgba(255, 255, 255, 0.226);
  }
  
  .downArrowDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: 1%;
  }
  
  .downArrow {
    width: 75px;
    height: 75px;
    animation: arrow_down_animation 2s ease-in-out infinite;
  }
  
  .introduction2 button {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border: none;
    font-size: 60px;
    border: none;
    display: flex;
    border-radius: 22px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -10px 0 inset;
  }
  
  @keyframes arrow_down_animation {
  
    0% {
      transform: translate3d(0, 0, 0) scale(1, 1);
      opacity: 0;
    }
  
    30% {
      opacity: 1;
    }
  
    100% {
      transform: translate3d(0, 75%, 0) scale(0.75, 1.2);
      opacity: 0;
  
    }
  
  }
  
  .wallet2 {
    z-index: 1000;
    background-color: #000000;
    color: #ffffff;
    padding: 13px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    border-radius: 50px;
    width: 270px;
    transition: transform .2s;
    border: none;
    font-family: "Gordita";
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
    transition: 0.5s ease;
    cursor: pointer;
    box-shadow: 0 3px #363636;
    letter-spacing: 2px;
  }
  
  .wallet2:hover {
    color: #000000;
    background-color: #ffffff;
  }
  
  .wallet2Btn {
    background-color: rgba(53, 52, 52, 0);
    border-style: solid;
    color: white;
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 12px;
    border-radius: 25px;
  
    box-shadow: rgba(56, 56, 56, 0.35) 0px 5px 15px;
    width: 400px;
    border: 2px solid #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border: 2px solid #2E8B57;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.05) inset, 0px 0px 8px rgb(88, 182, 88);
  }
  
  .wallet2Btn:hover {
    color: rgb(156, 156, 156);
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  
  .wallet3 {
    background-image: linear-gradient(to right top, #d16ba5, #c777b9, #ba83ca, #aa8fd8, #9a9ae1, #8aa7ec, #79b3f4, #69bff8, #52cffe, #41dfff, #46eefa, #5ffbf1);
    color: rgb(0, 0, 0);
    padding: 15px 25px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    border-radius: 15px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 50px;
    padding-right: 50px;
    box-shadow: black 0px 5px;
  }
  
  .wallet3:hover {
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes slidebg {
    to {
      background-position: 20vw;
    }
  }
  
  .in2 {
    width: 100%;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    flex-flow: column nowrap;
  }
  
  .totalSupply {
    text-align: center;
    font-size: 190px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: rgb(0, 0, 0);
    font-family: "Burbank Big Condensed";
  
    -webkit-text-stroke: 1px #a0dbfd;
    margin-top: 1%;
  }
  
  @keyframes Slide_Up {
    0% {
      transform: translateY(250px);
      opacity: 1;
    }
  
    10%,
    87.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Tag {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 1;
    }
  
    20%,
    92.5% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  @keyframes Slide_Up_Buttons {
    0% {
      transform: translateY(250px);
      opacity: 0;
    }
  
    10% {
      opacity: 0;
    }
  
    20% {
      opacity: 1;
    }
  
    25%,
    95% {
      transform: translateY(0);
    }
  
    90% {
      opacity: 1;
    }
  
    95% {
      opacity: 0;
    }
  
    100% {
      transform: translateY(250px);
      opacity: 0;
    }
  
  }
  
  .copyright {
    color: rgb(255, 255, 255);
    text-align: center;
    font-size: 40px;
    text-shadow: 0 0 10px #FFFFFF;
    margin-top: 18px;
  }
  
  .btn3 {
    flex-flow: row nowrap;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .link {
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  
  @keyframes shake {
  
    10%,
    90% {
      transform: translate3d(-1px, 0, 0);
    }
  
    20%,
    80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    40%,
    60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  @keyframes Slide_Left {
    0% {
      transform: translateX(250px);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes Slide_Left_Right {
  
    0%,
    100% {
      transform: translateX(100px);
    }
  
    12.5%,
    75% {
      transform: translateX(0);
    }
  
    25% {
      transform: translateX(20px);
    }
  
  }
  
  @keyframes Opacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    25%,
    75% {
      opacity: 1;
    }
  
  
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px -10px rgb(127, 212, 48);
    }
  
    to {
      box-shadow: 0 0 10px 10px rgba(182, 219, 96, 0.233);
    }
  }
  
  @keyframes scaleIn {
    from {
      transform: scale(.5, .5);
      opacity: .5;
    }
  
    to {
      transform: scale(2.5, 2.5);
      opacity: 0;
    }
  }
  
  @keyframes zoominoutsinglefeatured {
    0% {
      transform: scale(1, 1);
    }
  
    50% {
      transform: scale(1.05, 1.05);
    }
  
    100% {
      transform: scale(1, 1);
    }
  }
  
  .logo {
    width: 250px;
    height: 250px;
  }
  
  .logo2 {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  #fontSize {
    font-size: 50px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
    z-index: 10000;
    color: #90d3ff;
  }
  
  .parrotDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  
  }
  
  .pic1 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    left: 70%;
    bottom: 0;
  
  }
  
  .pic2 {
    width: 32%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    left: -5%;
    bottom: 0;
  
  }
  
  /*.............. FAQ ..............*/
  
  
  
  summary {
    font-size: 50px;
    background: rgba(19, 19, 19, 0.767);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    color: rgb(0, 0, 0);
    padding: 2rem;
    margin-bottom: 5rem;
    outline: none;
    text-align: left;
    cursor: pointer;
    border: #e2e2e2 2px ridge;
    box-shadow: 0 0 15px #704531;
    color: white;
    text-shadow: 0 0 10px #FFFFFF;
  }
  
  details>summary::-webkit-details-marker {
    display: none;
  }
  
  details[open] summary~* {
    animation: sweep .5s ease-in-out;
  }
  
  @keyframes sweep {
    0% {
      opacity: 0;
      margin-top: -10px
    }
  
    100% {
      opacity: 1;
      margin-top: 0px
    }
  }
  
  .accordin {
    width: 70%;
    color: white;
    display: inline-block;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .faq__content {
    color: rgb(255, 255, 255);
    padding-bottom: 20px;
    text-align: left;
    font-size: 50px;
    text-shadow: 0 0 10px #f8c9b3;
  
  }
  
  .faqTitle {
    margin-bottom: 20px;
    text-align: center;
    font-size: 70px;
    color: white;
  }
  
  .faqbg {
    width: 100%;
    text-align: left;
    padding-bottom: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 100%;
    padding-top: 5%;
    background: radial-gradient(circle, rgba(0, 0, 0, 0.411) 1%, rgba(0, 0, 0, 0.534) 49%, rgb(0, 0, 0) 100%), url('https://cdn.midjourney.com/98bbae52-dae5-41f6-9398-bcb202f29c50/0_2.png') no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .cover {
    width: 100vw;
  }
  
  .cover2 {
    width: 100%;
  }
  
  .parrotPic {
    width: 100vw;
  }
  
  .parrot {
    width: 70%;
    border-radius: 30px;
  }
  
  .pDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  
  .rmMain {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: -100px;
  }
  
  .rmBox {
    text-align: right;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox2 {
    text-align: right;
    width: 80%;
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 20px;
  }
  
  .rmBox {
    font-weight: 700;
    color: rgb(58, 58, 58);
    margin-bottom: 60%;
  }
  
  .year {
    font-size: 18px;
  
    color: rgb(8, 81, 94);
    letter-spacing: 1px;
  }
  
  
  .rmBoxMain2 {
    margin-top: 30%;
    width: 33.33%;
  }
  
  .rmBoxMain {
    width: max-content;
    justify-content: flex-start;
    width: 33.33%;
  
  }
  
  .rmBox2 {
    text-align: left;
    width: 30%;
  }
  
  .rmParrot {
    width: 130px;
    height: 186px;
    z-index: 1;
    justify-content: center;
    position: -webkit-sticky;
    position: sticky;
    top: 10%;
    margin-bottom: 25%;
    margin-right: auto;
    display: block;
    margin-left: 33%;
    margin-top: -50%;
  }
  
  .line {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 270vh;
    margin-top: -30%;
  }
  
  .rmP {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 33.33%;
    height: fit-content;
  }
  
  .box {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-top: -15%;
  }
  
  .discordF {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .socialAndLogo {
    flex-flow: row nowrap;
    justify-content: space-between;
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 10%;
    padding-top: 10%;
  }
  
  .chartAndContent {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
  }
  
  .tCon {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    color: white;
    font-weight: 500;
  }
  
  .tCon p {
    /* From https://css.glass */
    background: rgba(255, 255, 255, 0.2);
    border-radius: 5px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    padding: 13px;
  }
  
  .chart {
    width: 60%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .boxWrapToken {
    display: flex;
    flex-flow: column nowrap;
    padding-top: 5%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    padding-left: 5%;
    padding-right: 5%;
    background: rgb(74, 175, 139);
    background: linear-gradient(0deg, rgba(74, 175, 139, 1) 0%, rgba(88, 176, 135, 1) 18%, rgba(74, 175, 128, 1) 100%);
  }
  
  .tH {
    color: #3b3b3b;
    font-weight: bold;
  }
  
  .cont {
  
    height: fit-content;
    background:
      url('https://media.discordapp.net/attachments/1008571037862080542/1103543565226868828/NFTHouse9_eiffel_tower_sunny_day_beautiful_cartoon_64k_b9d28917-d03b-4743-bd43-e590b066d0a6.png?width=746&height=418') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
  
  .donutAndInfo {
    display: flex;
    flex-flow: row nowrap;
  }
  
  .donut {
    width: 45px;
    height: 45px;
    margin-right: 2%;
  }
  
  .rmCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    width: 90%;
  }
  
  .phrases p {
    font-size: 18px;
    letter-spacing: 2px;
  
  }
  
  .shoe {
    width: 400px;
    height: 400px;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .phrases {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    flex-flow: row nowrap;
    width: fit-content;
    margin-top: 3%;
  }
  
  .rmCon1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .teamCon {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    color: white;
    display: flex;
    flex-flow: column nowrap;
  
  }
  
  .teamCon img {
    width: 300px;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    border: 3px solid white;
  }
  
  .memberDetails {
    color: white;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .memberName {
    font-size: 23px;
    text-align: center;
  }
  
  .memberCon {
    font-size: 20px;
    text-align: center;
  }
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  .ticker-wrap {
    width: 100%;
    overflow: hidden;
    height: 16rem;
    background-image: url('./assets/line.png');
    padding-left: 100%;
    background-size: contain;
  }
  
  .ticker-wrap .ticker {
    display: inline-block;
    height: 16rem;
    line-height: 16rem;
    white-space: nowrap;
    padding-right: 100%;
    box-sizing: content-box;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: ticker;
    animation-name: ticker;
    -webkit-animation-duration: 30s;
    animation-duration: 60s;
  }
  
  .ticker-wrap .ticker__item {
    display: inline-block;
    padding: 0 3rem;
    font-size: 75px;
    color: #ffffff;
    font-family: "Burbank Big Condensed";
    letter-spacing: 2px;
  }
  
  .shark {
    width: 400px;
    height: 572px;
    z-index: 1;
    margin-right: -3%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
  }
  
  .teamMain {
    display: flex;
  }
  
  
  .memName {
  
    color: #fe8f00;
    font-size: 23px;
    text-align: center;
    padding-right: 2%;
  
  }
  
  .memNamePosition {
    color: rgb(0, 0, 0);
    font-size: 17px;
    text-align: center;
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .man {
    width: 700px;
    height: 700px;
    margin-top: -1%;
    position: sticky;
    bottom: 0;
  }
  
  
  .tPicDiv {
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    display: flex;
  }
  
  .subT {
  
    color: #f9f305;
    font-size: 50px;
    text-align: left;
  }
  
  .manDiv {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
    margin-bottom: 15px;
  }
  
  .memName2 {
    color: rgb(255, 255, 255);
    text-align: center;
    font-weight: 500;
    text-shadow: 0 0 10px rgb(255, 255, 255);
  }
  
  .rmDetails p {
    color: rgb(209, 209, 209);
    padding-left: 20px;
    font-size: 45px;
  }
  
  .flowers {
    width: 100%;
    margin-top: -10%;
  }
  
  .rmPicAndCon {
    display: flex;
    justify-content: flex-start;
    margin-left: 20px;
  }
  
  .footPrint2 {
    width: 60px;
    height: 60px;
  }
  
  
  /* Background */
  .mainFoot {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    margin-top: -10%;
  }
  
  /* Footprint */
  .footprint {
    position: absolute;
    top: 20%;
    left: 0;
    animation: walk 5s steps(10, end) infinite, walkopacity 2s ease-out infinite;
    text-align: center;
  }
  
  /* Image Size */
  .foot {
    max-width: 20%;
  }
  
  /* Right Footprint */
  .rightFoot {
    margin: 5rem;
    animation-delay: 250ms;
  }
  
  /* Walking Animation */
  @keyframes walk {
    to {
      transform: translateX(100vw);
    }
  }
  
  /* Opacity During Walking */
  @keyframes walkopacity {
  
    0%,
    100% {
      opacity: 0;
    }
  
    50% {
      opacity: 0.4;
    }
  }
  
  /* Define the keyframes for the floating animation */
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  
  /* CSS */
  .button-52 {
    font-size: 18px;
    font-family: "Burbank Big Condensed";
    letter-spacing: 1px;
    padding: 13px 20px 13px;
    outline: 0;
    border: 1px solid rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    background-color: rgba(0, 0, 0, 0);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 250px;
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
  }
  
  .button-52:after {
    content: "";
    background-color: #f37539;
    width: 100%;
    z-index: -1;
    position: absolute;
    height: 100%;
    top: 7px;
    left: 7px;
    transition: 0.2s;
  }
  
  .button-52:hover:after {
    top: 0px;
    left: 0px;
  }
  
  @media (min-width: 768px) {
    .button-52 {
      padding: 13px 50px 13px;
    }
  }
  
  
  .container video {
    width: 90%;
  
    height: 100%;
    object-fit: cover;
    -webkit-box-reflect: below 2px linear-gradient(transparent, transparent, #0004);
    transform-origin: center;
    transform: perspective(800px) rotateY(30deg);
    transition: 0.5s;
    box-shadow: 0 0 10px #01a5c2;
    border: 2px solid #0080c8;
  }
  
  .container {
    max-width: 750px;
    max-height: 380px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15%;
  }
  
  .container:hover video {
    opacity: 0.3;
  }
  
  .container video:hover {
    transform: perspective(800px) rotateY(0deg);
    opacity: 1;
  }
  
  /* Define the animation */
  @keyframes bounce {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-100px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  /* Apply the animation to an element */
  #ball {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 100%;
    bottom: 0px;
  }
  
  #ball3 {
    width: 270px;
    height: 270px;
    animation: bounce 1.5s infinite;
    position: sticky;
    position: -webkit-sticky;
    left: 5%;
    bottom: 0px;
  }
  
  .ball2 {
    width: 250px;
    height: 250px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;
  
    /* Animation to spin and move the sphere */
    -webkit-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -moz-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    -ms-animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
    animation: spin 1000ms linear infinite, moveLeftToRight 5s linear infinite;
  
    -webkit-transition: all 1s ease;
    transition: all 1s ease;
    position: absolute;
    left: 0;
  }
  
  .ballDiv {
    position: relative;
    height: 300px;
    padding-top: 30px;
  }
  
  /* Spinning the sphere using key frames */
  @-ms-keyframes spin {
    from {
      -ms-transform: rotate(0deg);
    }
  
    to {
      -ms-transform: rotate(360deg);
    }
  }
  
  @-moz-keyframes spin {
    from {
      -moz-transform: rotate(0deg);
    }
  
    to {
      -moz-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }
  
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
    }
  
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  
  /* Move sphere from left to right */
  @-moz-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-ms-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @-webkit-keyframes moveLeftToRight {
    0% {
      left: -100px;
    }
  
    100% {
      left: 100%;
    }
  }
  
  @keyframes football-rolling {
    0% {
      transform: rotate(0deg);
    }
  
    100% {
      transform: rotate(360deg);
    }
  }
  
  
  :root {
    --color1: #55476A;
    --color2: #AE3D63;
    --color3: #DB3853;
    --color4: #F45C44;
    --color5: #F8B663;
  }
  
  .container2 {
    min-height: 100vh;
    overflow: hidden;
    padding: 60px;
    position: relative;
    width: 100%;
  }
  
  .confetti {
    position: absolute;
    display: flex;
    width: 10px;
    height: 25px;
    top: -100px;
  }
  
  .confetti:nth-child(1) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 10%;
  }
  
  .confetti:nth-child(2) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite .2s;
    left: 20%;
  }
  
  .confetti:nth-child(3) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .4s;
    left: 30%;
  }
  
  .confetti:nth-child(4) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite .1s;
    left: 40%;
  }
  
  .confetti:nth-child(5) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite .7s;
    left: 50%;
  }
  
  .confetti:nth-child(6) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite .2s;
    left: 60%;
  }
  
  .confetti:nth-child(7) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite .7s;
    left: 70%;
  }
  
  .confetti:nth-child(8) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite .9s;
    left: 80%;
  }
  
  .confetti:nth-child(9) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite .9s;
    left: 90%;
  }
  
  .confetti:nth-child(10) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 100%;
  }
  
  .confetti:nth-child(11) {
    background-color: var(--color1);
    animation: fall2 2.5s linear infinite;
    left: 95%;
  }
  
  .confetti:nth-child(12) {
    background-color: var(--color2);
    animation: fall2 2.3s linear infinite 1.1s;
    left: 85%;
  }
  
  .confetti:nth-child(13) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.2s;
    left: 75%;
  }
  
  .confetti:nth-child(14) {
    background-color: var(--color4);
    animation: fall2 2.7s linear infinite 1.3s;
    left: 65%;
  }
  
  .confetti:nth-child(15) {
    background-color: var(--color5);
    animation: fall2 2.6s linear infinite 1.4s;
    left: 55%;
  }
  
  .confetti:nth-child(16) {
    background-color: var(--color1);
    animation: fall2 2.4s linear infinite 1.5s;
    left: 45%;
  }
  
  .confetti:nth-child(17) {
    background-color: var(--color2);
    animation: fall2 2.1s linear infinite 1.6s;
    left: 35%;
  }
  
  .confetti:nth-child(18) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite 1.7s;
    left: 25%;
  }
  
  .confetti:nth-child(19) {
    background-color: var(--color4);
    animation: fall2 2.9s linear infinite 1.8s;
    left: 15%;
  }
  
  .confetti:nth-child(20) {
    background-color: var(--color5);
    animation: fall2 2.2s linear infinite 1.9s;
    left: 5%;
  }
  
  .confetti:nth-child(21) {
    background-color: var(--color1);
    animation: fall 2.5s linear infinite;
    left: 3%;
  }
  
  .confetti:nth-child(22) {
    background-color: var(--color2);
    animation: fall 2.3s linear infinite 1.3s;
    left: 13%;
  }
  
  .confetti:nth-child(23) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.4s;
    left: 23%;
  }
  
  .confetti:nth-child(24) {
    background-color: var(--color4);
    animation: fall 2.7s linear infinite 1.5s;
    left: 33%;
  }
  
  .confetti:nth-child(25) {
    background-color: var(--color5);
    animation: fall 2.6s linear infinite 1.6s;
    left: 43%;
  }
  
  .confetti:nth-child(26) {
    background-color: var(--color1);
    animation: fall 2.4s linear infinite 1.2s;
    left: 53%;
  }
  
  .confetti:nth-child(27) {
    background-color: var(--color2);
    animation: fall 2.1s linear infinite 1.7s;
    left: 63%;
  }
  
  .confetti:nth-child(28) {
    background-color: var(--color3);
    animation: fall 2.4s linear infinite 1.8s;
    left: 73%;
  }
  
  .confetti:nth-child(29) {
    background-color: var(--color4);
    animation: fall 2.9s linear infinite 1.9s;
    left: 83%;
  }
  
  .confetti:nth-child(30) {
    background-color: var(--color5);
    animation: fall 2.2s linear infinite 1.1s;
    left: 93%;
  }
  
  .confetti:nth-child(31) {
    background-color: var(--color1);
    animation: fall2 2.2s linear infinite 1.1s;
    left: 7%;
  }
  
  .confetti:nth-child(32) {
    background-color: var(--color2);
    animation: fall2 2.9s linear infinite .9s;
    left: 17%;
  }
  
  .confetti:nth-child(33) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .1s;
    left: 27%;
  }
  
  .confetti:nth-child(34) {
    background-color: var(--color4);
    animation: fall2 2.1s linear infinite .7s;
    left: 37%;
  }
  
  .confetti:nth-child(35) {
    background-color: var(--color5);
    animation: fall2 2.4s linear infinite .2s;
    left: 47%;
  }
  
  .confetti:nth-child(36) {
    background-color: var(--color1);
    animation: fall2 2.6s linear infinite .7s;
    left: 57%;
  }
  
  .confetti:nth-child(37) {
    background-color: var(--color2);
    animation: fall2 2.7s linear infinite .9s;
    left: 67%;
  }
  
  .confetti:nth-child(38) {
    background-color: var(--color3);
    animation: fall2 2.4s linear infinite .4s;
    left: 77%;
  }
  
  .confetti:nth-child(39) {
    background-color: var(--color4);
    animation: fall2 2.3s linear infinite .2s;
    left: 87%;
  }
  
  .confetti:nth-child(40) {
    background-color: var(--color5);
    animation: fall2 2.5s linear infinite .3s;
    left: 97%;
  }
  
  @keyframes fall {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(-90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(360deg) rotateY(180deg);
      opacity: 0.7;
    }
  }
  
  @keyframes fall2 {
    0% {
      top: -110px;
      transform: rotate(0deg) rotateY(90deg);
      opacity: 1;
    }
  
    100% {
      top: 900px;
      transform: rotate(-360deg) rotateY(-180deg);
      opacity: 0.5;
    }
  }
  
  .paper {
    width: 100%;
  }
  
  .rmBg {
    position: relative;
    height: fit-content;
    display: flex;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
  }
  
  .rmPicDiv {
    position: relative;
    flex-flow: column nowrap;
    background-color: #0000f6;
  }
  
  .rmPic {
    width: 90%;
     position: absolute;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 5%;
  }
  
  .buildings {
    width: 18%;
    z-index: 100000000000000000000;
  }
  
  .animateRm {
    transform: rotate(45deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm2 {
    transform: rotate(90deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm3 {
    transform: rotate(135deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .animateRm4 {
    transform: rotate(150deg);
    transition: transform 1s ease;
    /* Change the duration and easing to your liking */
  
  }
  
  .mapBg {
  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
  
    height: 190vh;
  }
  
.building1 {
  width: 18%;
  margin-right: 45%;
  margin-top: 37%;
  position: absolute;
  z-index: 1000;
  cursor: pointer;
}

.baloon2{
  width: 16%;
  margin-right: 20%;
  margin-top: 33%;
  position: absolute;
  z-index: 1000;
  animation: floating 8s ease-in-out infinite;
}

.building1:hover+.q1 {
  cursor: pointer;
  margin-top: 35%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-right: 2%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q1 {
  display: none;

}

.building2:hover+.q2 {
  cursor: pointer;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 64%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q2 {
  display: none;

}

.building3:hover+.q3 {
  cursor: pointer;
  margin-top: 65%;
  z-index: 10000;
  width: 30%;
  display: block;
  margin-right: 15%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q3 {
  display: none;

}

.building4:hover+.q4 {
  cursor: pointer;
  margin-top: 67%;
  z-index: 10000;
  width: 27%;
  display: block;
  margin-left: 8%;
  opacity: 0;
  animation: popup 0.3s ease forwards;
}

@keyframes popup {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.q4 {
  display: none;

}

  .ball1-rm {
    width: 6%;
    margin-right: 26%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.1s infinite;
  }
  
  .ball5-rm {
    width: 6%;
    margin-left: 65%;
    margin-top: 22%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.2s infinite;
  
  }
  
  @keyframes bounce2 {
    0% {
      transform: translateY(0) rotate(0deg);
    }
  
    50% {
      transform: translateY(-70px);
    }
  
    100% {
      transform: translateY(0) rotate(360deg);
    }
  }
  
  .baloon1{
    width: 16%;
    margin-left: -5%;
    margin-top: 2%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  @keyframes floating {
    0% {
      transform: translateY(0);
    }
  
    50% {
      transform: translateY(-10px);
    }
  
    100% {
      transform: translateY(0);
    }
  }
  
  .building2 {
    width: 18%;
    margin-left: 18%;
    margin-top: 6%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .baloon4{
    width: 16%;
    margin-left: -37%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    animation: floating 6s ease-in-out infinite;
  }
  
  .airB{
    width: 35%;
    margin-left: -37%;
    margin-top: 100%;
    position: absolute;
    z-index: 1000;
    transform: translateY(-50%);
    animation-name: balloon-float;
    animation-duration: 30s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes balloon-float {
    0% {
      left: -50px;
    }
    50% {
      transform: translateY(-60%) rotate(5deg);
    }
    100% {
      left: 150%;
      transform: translateY(-50%) rotate(0deg);
    }
  }
  
  .building3 {
    width: 25%;
    margin-left: -60%;
    margin-top: 60%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .ball3-rm {
    width: 5%;
    margin-left: 72%;
    margin-top: 45%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 2s infinite;
  
  }
  
  
  .ball4-rm {
    width: 6%;
    margin-left: 72%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball5-rm {
    width: 5%;
    margin-left: 15%;
    margin-top: 73%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1s infinite;
  
  }
  
  .ball6-rm {
    width: 4%;
    margin-right: 70%;
    margin-top: 56%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball9-rm {
    width: 4%;
    margin-right: -65%;
    margin-top: 5%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball7-rm {
    width: 5%;
    margin-left: 10%;
    margin-top: 28%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  
  }
  
  .ball8-rm {
    width: 4%;
    margin-left: 65%;
    margin-top: 18%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
    animation: bounce2 1.8s infinite;
  }
  
  .baloon3{
    width: 16%;
    margin-left: 30%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    animation: floating 5s ease-in-out infinite;
  }
  
  .building4 {
    width: 20%;
    margin-left: 45%;
    margin-top: 70%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium5 {
    width: 18%;
    margin-right: 36%;
    margin-top: 68%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .stadium6 {
    width: 18%;
    margin-left: 55%;
    margin-top: 80%;
    position: absolute;
    z-index: 1000;
    cursor: pointer;
  }
  
  .cup1 {
    width: 10%;
    margin-right: 70%;
    margin-top: 76%;
    position: absolute;
    z-index: 10000;
  }
  
  .shiny {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.774);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 5px;
    height: 5px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny2 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 1000;
    margin-right: 67%;
    margin-top: 77%;
  }
  
  .shiny3 {
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.808);
    /* Add a transition effect to the element */
    transition: box-shadow 0.5s;
    animation: glowing 1s infinite;
    width: 50px;
    height: 50px;
    background-color: white;
    border-radius: 100%;
    position: absolute;
    z-index: 100;
    margin-left: 27.5%;
    margin-top: 5%;
  }
  
  /* CSS */
  .button-51 {
    background-color: transparent;
    border: 1px solid #266DB6;
    box-sizing: border-box;
    color: #00132C;
    font-family: "Avenir Next LT W01 Bold", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 16px 23px;
    position: relative;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
  }
  
  .button-51:hover,
  .button-51:active {
    outline: 0;
  }
  
  .button-51:hover {
    background-color: transparent;
    cursor: pointer;
  }
  
  .button-51:before {
    background-color: #D5EDF6;
    content: "";
    height: calc(100% + 3px);
    position: absolute;
    right: -7px;
    top: -9px;
    transition: background-color 300ms ease-in;
    width: 100%;
    z-index: -1;
  }
  
  .button-51:hover:before {
    background-color: #6DCFF6;
  }
  
  @media (min-width: 768px) {
    .button-51 {
      padding: 16px 32px;
    }
  }
  
  @keyframes glowing {
  
    /* Start the animation at 50% */
    50% {
      /* Increase the size and intensity of the shiny effect */
      box-shadow: 0 0 160px 5px rgb(255, 176, 4);
    }
  
    /* End the animation at 100% */
    100% {
      /* Return the element to its original state */
      box-shadow: 0 0 160px 2px rgb(255, 255, 255);
    }
  }
  
  .cup2 {
    width: 12%;
    margin-right: -21%;
    margin-top: 4%;
    position: absolute;
    z-index: 1000;
  }
  
  .partners {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: 50px;
  }
  
  .partnerDiv {
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    width: 70%;
    display: flex;
  }
  
  .partnerDiv img {
    width: 450px;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    margin-top: -80px;
    opacity: 0.6;
    transition: 0.3s;
    cursor: pointer;
    margin-left: -40px;
    margin-right: -40px;
  }
  
  .partnerDiv img:hover {
    opacity: 1
  }
  
  /* CSS */
  .button-78 {
    align-items: center;
    appearance: none;
    background-clip: padding-box;
    background-color: initial;
    background-image: none;
    border-style: none;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    flex-direction: row;
    flex-shrink: 0;
    font-family: "Burbank Big Condensed";
    font-size: 30px;
    justify-content: center;
    line-height: 24px;
    margin: 0;
    min-height: 64px;
    outline: none;
    overflow: visible;
    padding: 19px 26px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    width: auto;
    word-break: keep-all;
    z-index: 0;
    width: 200px;
    letter-spacing: 3px;
    animation: floating 2s ease-in-out infinite;
  }
  
  .translate{
    position: sticky;
    bottom: 23%;
    margin-left: 90%;
     cursor: pointer;
    z-index: 1;
    z-index: 100000;
   
  }
  
  .translate img{
    width: 160px;
    height: 160px;
  }
  
  .translate:hover{
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    perspective: 1000px;
  }
  
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
  
  .stickyBtn {
    position: sticky;
    bottom: -7%;
    margin-left: -5%;
    margin-top: -30%;
    width: 400px;
    height: 400px;
    cursor: pointer;
    z-index: 1;
    animation: floating 2s ease-in-out infinite;
    z-index: 100000;
  }
  
  .stickyBtn2 {
  
    width: 160px;
    height: 160px;
  
  }
  
  #sticky-button {
    position: sticky;
    visibility: hidden;
    position: sticky;
    bottom: 7%;
    margin-left: 90%;
    margin-top: -10%;
  
    cursor: pointer;
    z-index: 1;
    z-index: 100000;
    transition: transform .4s;
  }
  
  .stickyBtn2:hover {
    transform: scale(1.1);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  @media (min-width: 768px) {
    .button-78 {
      padding: 19px 32px;
    }
  }
  
  .button-78:before,
  .button-78:after {
    border-radius: 80px;
  }
  
  .button-78:before {
    background-image: linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }
  
  .button-78:after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }
  
  .button-78:hover:not(:disabled):before {
    background: linear-gradient(92.83deg, rgb(255, 116, 38) 0%, rgb(249, 58, 19) 100%);
  }
  
  .button-78:hover:not(:disabled):after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }
  
  .button-78:active:not(:disabled) {
    color: #ccc;
  }
  
  .button-78:active:not(:disabled):before {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .2), rgba(0, 0, 0, .2)), linear-gradient(92.83deg, #ff7426 0, #f93a13 100%);
  }
  
  .button-78:active:not(:disabled):after {
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    left: 4px;
    right: 4px;
    top: 4px;
  }
  
  .button-78:disabled {
    cursor: default;
    opacity: .24;
  }
  
  a {
    color: #83b2f8;
  }
  
  a:hover {
    color: #5f9fff;
    text-decoration: underline;
  }

  .mountainsMain{
    margin-top: -80%;
  }
   
  @keyframes flame {
    0% {
      transform: translateX(0%);
    }
  
    50% {
      transform: translateX(50%);
    }
  
    100% {
      transform: translateX(0%);
    }
  }
  
  .video {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    margin-top: 3%;
    margin-bottom: 3%;
    border-radius: 10px;
  }
  
  .imgAndCon {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  
  }
  
  .imgAndCon {
    transition: transform .5s;
  }
  
  .imgAndCon:hover {
    transform: scale(1.2);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
  }
  
  .imgAndCon img {
    width: 1000px;
    height: 1000px;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
  }
  
  .imgAndCon div {
    color: rgb(255, 255, 255);
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
    width: 1000px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    background: rgb(0, 46, 66);
    background: linear-gradient(16deg, rgba(0, 46, 66, 1) 0%, rgba(0, 73, 105, 0.5424274916059515) 47%);
    font-size: 40px;
  }
  
  .imgAndConMain {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-between;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 100%;
  }
  
  .selectMain{
    height: 100vh;
    width: 100vh;
    background-color: #000000;
  }
  .formMain1 {
    /*transform: translate(-50%, -50%);*/
    position: fixed;
    width: 100%;
    height: 100vh;
    /*top: 50%;*/
    /*left: 50%;*/
    z-index: 1000000000000000;
    background-color: rgba(24, 24, 26, 0.842);
  }
  
  .formMain {
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.856);
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    z-index: 1000000000000000;
    overflow: scroll;
    overflow-x: hidden;
    border-radius: 10px;
    z-index: 100000;
    border: white 3px solid;
    display: flex;
    flex-flow: row nowrap;
  }
  
  .closeNote {
    position: absolute;
    right: 0;
    top: 1%;
    color: rgba(0, 0, 0, 0.836);
    font-size: 30px;
    font-weight: bold;
    background-color: rgba(172, 67, 67, 0);
    width: 100px;
    height: fit-content;
    cursor: pointer;
    border: none;
  }
  
  .closeNote:hover,
  .closeNote:focus {
    color: rgb(0, 0, 0);
  }
  
  /* CSS */
  .button-29 {
    align-items: center;
    appearance: none;
    background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
    border: 0;
    border-radius: 10px;
    box-shadow: rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, rgba(58, 65, 111, .5) 0 -10px 0 inset;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    height: 110px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 40px;
    padding-right: 40px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s, transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 50px;
  }
  
  .button-29:focus {
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
  }
  
  .button-29:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }
  
  .button-29:active {
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .tokenCon {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    text-align: center;
    color: rgba(0, 0, 0, 0.801);
    font-weight: bold;
    margin-top: 2%;
    font-size: 50px;
  }
  
  .tokenV {
    width: 45%;
    position: sticky;
    bottom: 8%;
    left: 90%;
  }
}

.circle-container2 {
  position: fixed;
  transform: translateY(-10vh);
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  height: 0;
}

.circle-container2 .circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  mix-blend-mode: screen;
  background-image: radial-gradient(#ffffff, rgba(250, 223, 133, 0.753) 10%, rgba(153, 255, 255, 0) 56%);
  animation: fadein-frames 200ms infinite, scale-frames 2s infinite;
}


@keyframes fade-frames {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale-frames {
  0% {
    transform: scale3d(0.4, 0.4, 1);
  }

  50% {
    transform: scale3d(2.2, 2.2, 1);
  }

  100% {
    transform: scale3d(0.4, 0.4, 1);
  }
}

.circle-container2:nth-child(1) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-1;
  animation-duration: 29142ms;
  animation-delay: 19846ms;
}

@keyframes move-frames-1 {
  from {
    transform: translate3d(48vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -102vh, 0);
  }
}

.circle-container2:nth-child(1) .circle {
  animation-delay: 2519ms;
}

.circle-container2:nth-child(2) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-2;
  animation-duration: 36368ms;
  animation-delay: 19125ms;
}

@keyframes move-frames-2 {
  from {
    transform: translate3d(36vw, 107vh, 0);
  }

  to {
    transform: translate3d(68vw, -128vh, 0);
  }
}

.circle-container2:nth-child(2) .circle {
  animation-delay: 1084ms;
}

.circle-container2:nth-child(3) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-3;
  animation-duration: 33108ms;
  animation-delay: 1936ms;
}

@keyframes move-frames-3 {
  from {
    transform: translate3d(51vw, 104vh, 0);
  }

  to {
    transform: translate3d(92vw, -124vh, 0);
  }
}

.circle-container2:nth-child(3) .circle {
  animation-delay: 1406ms;
}

.circle-container2:nth-child(4) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-4;
  animation-duration: 28877ms;
  animation-delay: 30762ms;
}

@keyframes move-frames-4 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(57vw, -113vh, 0);
  }
}

.circle-container2:nth-child(4) .circle {
  animation-delay: 2955ms;
}

.circle-container2:nth-child(5) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-5;
  animation-duration: 35824ms;
  animation-delay: 31074ms;
}

@keyframes move-frames-5 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(31vw, -124vh, 0);
  }
}

.circle-container2:nth-child(5) .circle {
  animation-delay: 1469ms;
}

.circle-container2:nth-child(6) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-6;
  animation-duration: 32429ms;
  animation-delay: 35335ms;
}

@keyframes move-frames-6 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -116vh, 0);
  }
}

.circle-container2:nth-child(6) .circle {
  animation-delay: 653ms;
}

.circle-container2:nth-child(7) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-7;
  animation-duration: 31901ms;
  animation-delay: 31819ms;
}

@keyframes move-frames-7 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -110vh, 0);
  }
}

.circle-container2:nth-child(7) .circle {
  animation-delay: 2166ms;
}

.circle-container2:nth-child(8) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-8;
  animation-duration: 28634ms;
  animation-delay: 18091ms;
}

@keyframes move-frames-8 {
  from {
    transform: translate3d(81vw, 105vh, 0);
  }

  to {
    transform: translate3d(20vw, -119vh, 0);
  }
}

.circle-container2:nth-child(8) .circle {
  animation-delay: 1433ms;
}

.circle-container2:nth-child(9) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-9;
  animation-duration: 31744ms;
  animation-delay: 15233ms;
}

@keyframes move-frames-9 {
  from {
    transform: translate3d(80vw, 101vh, 0);
  }

  to {
    transform: translate3d(54vw, -118vh, 0);
  }
}

.circle-container2:nth-child(9) .circle {
  animation-delay: 2399ms;
}

.circle-container2:nth-child(10) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-10;
  animation-duration: 29461ms;
  animation-delay: 5651ms;
}

@keyframes move-frames-10 {
  from {
    transform: translate3d(24vw, 109vh, 0);
  }

  to {
    transform: translate3d(35vw, -130vh, 0);
  }
}

.circle-container2:nth-child(10) .circle {
  animation-delay: 1080ms;
}

.circle-container2:nth-child(11) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-11;
  animation-duration: 30609ms;
  animation-delay: 34893ms;
}

@keyframes move-frames-11 {
  from {
    transform: translate3d(16vw, 103vh, 0);
  }

  to {
    transform: translate3d(94vw, -124vh, 0);
  }
}

.circle-container2:nth-child(11) .circle {
  animation-delay: 1477ms;
}

.circle-container2:nth-child(12) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-12;
  animation-duration: 29877ms;
  animation-delay: 33535ms;
}

@keyframes move-frames-12 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(32vw, -110vh, 0);
  }
}

.circle-container2:nth-child(12) .circle {
  animation-delay: 1105ms;
}

.circle-container2:nth-child(13) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-13;
  animation-duration: 34198ms;
  animation-delay: 21871ms;
}

@keyframes move-frames-13 {
  from {
    transform: translate3d(59vw, 101vh, 0);
  }

  to {
    transform: translate3d(92vw, -113vh, 0);
  }
}

.circle-container2:nth-child(13) .circle {
  animation-delay: 3982ms;
}

.circle-container2:nth-child(14) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-14;
  animation-duration: 31477ms;
  animation-delay: 23360ms;
}

@keyframes move-frames-14 {
  from {
    transform: translate3d(27vw, 105vh, 0);
  }

  to {
    transform: translate3d(46vw, -125vh, 0);
  }
}

.circle-container2:nth-child(14) .circle {
  animation-delay: 1912ms;
}

.circle-container2:nth-child(15) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-15;
  animation-duration: 35088ms;
  animation-delay: 19665ms;
}

@keyframes move-frames-15 {
  from {
    transform: translate3d(64vw, 104vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(15) .circle {
  animation-delay: 1404ms;
}

.circle-container2:nth-child(16) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-16;
  animation-duration: 31494ms;
  animation-delay: 1568ms;
}

@keyframes move-frames-16 {
  from {
    transform: translate3d(10vw, 109vh, 0);
  }

  to {
    transform: translate3d(21vw, -136vh, 0);
  }
}

.circle-container2:nth-child(16) .circle {
  animation-delay: 3706ms;
}

.circle-container2:nth-child(17) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-17;
  animation-duration: 33208ms;
  animation-delay: 30298ms;
}

@keyframes move-frames-17 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(75vw, -110vh, 0);
  }
}

.circle-container2:nth-child(17) .circle {
  animation-delay: 309ms;
}

.circle-container2:nth-child(18) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-18;
  animation-duration: 30460ms;
  animation-delay: 10375ms;
}

@keyframes move-frames-18 {
  from {
    transform: translate3d(59vw, 110vh, 0);
  }

  to {
    transform: translate3d(59vw, -138vh, 0);
  }
}

.circle-container2:nth-child(18) .circle {
  animation-delay: 1454ms;
}

.circle-container2:nth-child(19) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-19;
  animation-duration: 36863ms;
  animation-delay: 13714ms;
}

@keyframes move-frames-19 {
  from {
    transform: translate3d(74vw, 102vh, 0);
  }

  to {
    transform: translate3d(89vw, -117vh, 0);
  }
}

.circle-container2:nth-child(19) .circle {
  animation-delay: 2304ms;
}

.circle-container2:nth-child(20) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-20;
  animation-duration: 31143ms;
  animation-delay: 30049ms;
}

@keyframes move-frames-20 {
  from {
    transform: translate3d(62vw, 105vh, 0);
  }

  to {
    transform: translate3d(70vw, -126vh, 0);
  }
}

.circle-container2:nth-child(20) .circle {
  animation-delay: 923ms;
}

.circle-container2:nth-child(21) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-21;
  animation-duration: 29000ms;
  animation-delay: 33203ms;
}

@keyframes move-frames-21 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(48vw, -131vh, 0);
  }
}

.circle-container2:nth-child(21) .circle {
  animation-delay: 2225ms;
}

.circle-container2:nth-child(22) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-22;
  animation-duration: 36652ms;
  animation-delay: 17612ms;
}

@keyframes move-frames-22 {
  from {
    transform: translate3d(80vw, 104vh, 0);
  }

  to {
    transform: translate3d(74vw, -114vh, 0);
  }
}

.circle-container2:nth-child(22) .circle {
  animation-delay: 1317ms;
}

.circle-container2:nth-child(23) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-23;
  animation-duration: 34731ms;
  animation-delay: 22173ms;
}

@keyframes move-frames-23 {
  from {
    transform: translate3d(96vw, 109vh, 0);
  }

  to {
    transform: translate3d(88vw, -135vh, 0);
  }
}

.circle-container2:nth-child(23) .circle {
  animation-delay: 3929ms;
}

.circle-container2:nth-child(24) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-24;
  animation-duration: 32220ms;
  animation-delay: 3198ms;
}

@keyframes move-frames-24 {
  from {
    transform: translate3d(58vw, 102vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(24) .circle {
  animation-delay: 91ms;
}

.circle-container2:nth-child(25) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-25;
  animation-duration: 35525ms;
  animation-delay: 29589ms;
}

@keyframes move-frames-25 {
  from {
    transform: translate3d(68vw, 109vh, 0);
  }

  to {
    transform: translate3d(10vw, -115vh, 0);
  }
}

.circle-container2:nth-child(25) .circle {
  animation-delay: 2158ms;
}

.circle-container2:nth-child(26) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-26;
  animation-duration: 35875ms;
  animation-delay: 33656ms;
}

@keyframes move-frames-26 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -135vh, 0);
  }
}

.circle-container2:nth-child(26) .circle {
  animation-delay: 456ms;
}

.circle-container2:nth-child(27) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-27;
  animation-duration: 34577ms;
  animation-delay: 12215ms;
}

@keyframes move-frames-27 {
  from {
    transform: translate3d(85vw, 108vh, 0);
  }

  to {
    transform: translate3d(92vw, -116vh, 0);
  }
}

.circle-container2:nth-child(27) .circle {
  animation-delay: 1184ms;
}

.circle-container2:nth-child(28) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-28;
  animation-duration: 31908ms;
  animation-delay: 26869ms;
}

@keyframes move-frames-28 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(24vw, -118vh, 0);
  }
}

.circle-container2:nth-child(28) .circle {
  animation-delay: 673ms;
}

.circle-container2:nth-child(29) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-29;
  animation-duration: 33853ms;
  animation-delay: 288ms;
}

@keyframes move-frames-29 {
  from {
    transform: translate3d(26vw, 110vh, 0);
  }

  to {
    transform: translate3d(50vw, -128vh, 0);
  }
}

.circle-container2:nth-child(29) .circle {
  animation-delay: 676ms;
}

.circle-container2:nth-child(30) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-30;
  animation-duration: 32724ms;
  animation-delay: 9766ms;
}

@keyframes move-frames-30 {
  from {
    transform: translate3d(50vw, 108vh, 0);
  }

  to {
    transform: translate3d(52vw, -129vh, 0);
  }
}

.circle-container2:nth-child(30) .circle {
  animation-delay: 3712ms;
}

.circle-container2:nth-child(31) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-31;
  animation-duration: 30306ms;
  animation-delay: 4355ms;
}

@keyframes move-frames-31 {
  from {
    transform: translate3d(52vw, 109vh, 0);
  }

  to {
    transform: translate3d(55vw, -131vh, 0);
  }
}

.circle-container2:nth-child(31) .circle {
  animation-delay: 1652ms;
}

.circle-container2:nth-child(32) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-32;
  animation-duration: 36466ms;
  animation-delay: 23222ms;
}

@keyframes move-frames-32 {
  from {
    transform: translate3d(48vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -128vh, 0);
  }
}

.circle-container2:nth-child(32) .circle {
  animation-delay: 2661ms;
}

.circle-container2:nth-child(33) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-33;
  animation-duration: 28746ms;
  animation-delay: 31176ms;
}

@keyframes move-frames-33 {
  from {
    transform: translate3d(21vw, 109vh, 0);
  }

  to {
    transform: translate3d(28vw, -125vh, 0);
  }
}

.circle-container2:nth-child(33) .circle {
  animation-delay: 1657ms;
}

.circle-container2:nth-child(34) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-34;
  animation-duration: 36366ms;
  animation-delay: 10018ms;
}

@keyframes move-frames-34 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -123vh, 0);
  }
}

.circle-container2:nth-child(34) .circle {
  animation-delay: 3772ms;
}

.circle-container2:nth-child(35) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-35;
  animation-duration: 29767ms;
  animation-delay: 22107ms;
}

@keyframes move-frames-35 {
  from {
    transform: translate3d(34vw, 105vh, 0);
  }

  to {
    transform: translate3d(53vw, -126vh, 0);
  }
}

.circle-container2:nth-child(35) .circle {
  animation-delay: 3793ms;
}

.circle-container2:nth-child(36) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-36;
  animation-duration: 33419ms;
  animation-delay: 28077ms;
}

@keyframes move-frames-36 {
  from {
    transform: translate3d(29vw, 104vh, 0);
  }

  to {
    transform: translate3d(90vw, -119vh, 0);
  }
}

.circle-container2:nth-child(36) .circle {
  animation-delay: 2534ms;
}

.circle-container2:nth-child(37) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-37;
  animation-duration: 30556ms;
  animation-delay: 30570ms;
}

@keyframes move-frames-37 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(27vw, -133vh, 0);
  }
}

.circle-container2:nth-child(37) .circle {
  animation-delay: 323ms;
}

.circle-container2:nth-child(38) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-38;
  animation-duration: 33679ms;
  animation-delay: 27547ms;
}

@keyframes move-frames-38 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(38) .circle {
  animation-delay: 3503ms;
}

.circle-container2:nth-child(39) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-39;
  animation-duration: 36428ms;
  animation-delay: 130ms;
}

@keyframes move-frames-39 {
  from {
    transform: translate3d(92vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(39) .circle {
  animation-delay: 2613ms;
}

.circle-container2:nth-child(40) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-40;
  animation-duration: 36739ms;
  animation-delay: 33514ms;
}

@keyframes move-frames-40 {
  from {
    transform: translate3d(74vw, 106vh, 0);
  }

  to {
    transform: translate3d(49vw, -132vh, 0);
  }
}

.circle-container2:nth-child(40) .circle {
  animation-delay: 2325ms;
}

.circle-container2:nth-child(41) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-41;
  animation-duration: 30196ms;
  animation-delay: 20811ms;
}

@keyframes move-frames-41 {
  from {
    transform: translate3d(45vw, 106vh, 0);
  }

  to {
    transform: translate3d(56vw, -121vh, 0);
  }
}

.circle-container2:nth-child(41) .circle {
  animation-delay: 3688ms;
}

.circle-container2:nth-child(42) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-42;
  animation-duration: 36980ms;
  animation-delay: 20720ms;
}

@keyframes move-frames-42 {
  from {
    transform: translate3d(89vw, 109vh, 0);
  }

  to {
    transform: translate3d(33vw, -123vh, 0);
  }
}

.circle-container2:nth-child(42) .circle {
  animation-delay: 744ms;
}

.circle-container2:nth-child(43) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-43;
  animation-duration: 34954ms;
  animation-delay: 28366ms;
}

@keyframes move-frames-43 {
  from {
    transform: translate3d(12vw, 106vh, 0);
  }

  to {
    transform: translate3d(47vw, -136vh, 0);
  }
}

.circle-container2:nth-child(43) .circle {
  animation-delay: 763ms;
}

.circle-container2:nth-child(44) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-44;
  animation-duration: 35359ms;
  animation-delay: 8123ms;
}

@keyframes move-frames-44 {
  from {
    transform: translate3d(31vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(44) .circle {
  animation-delay: 2923ms;
}

.circle-container2:nth-child(45) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-45;
  animation-duration: 32140ms;
  animation-delay: 3024ms;
}

@keyframes move-frames-45 {
  from {
    transform: translate3d(11vw, 110vh, 0);
  }

  to {
    transform: translate3d(89vw, -133vh, 0);
  }
}

.circle-container2:nth-child(45) .circle {
  animation-delay: 1863ms;
}

.circle-container2:nth-child(46) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-46;
  animation-duration: 28466ms;
  animation-delay: 11332ms;
}

@keyframes move-frames-46 {
  from {
    transform: translate3d(87vw, 102vh, 0);
  }

  to {
    transform: translate3d(82vw, -108vh, 0);
  }
}

.circle-container2:nth-child(46) .circle {
  animation-delay: 1494ms;
}

.circle-container2:nth-child(47) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-47;
  animation-duration: 30852ms;
  animation-delay: 26331ms;
}

@keyframes move-frames-47 {
  from {
    transform: translate3d(95vw, 101vh, 0);
  }

  to {
    transform: translate3d(97vw, -105vh, 0);
  }
}

.circle-container2:nth-child(47) .circle {
  animation-delay: 3888ms;
}

.circle-container2:nth-child(48) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-48;
  animation-duration: 32146ms;
  animation-delay: 33093ms;
}

@keyframes move-frames-48 {
  from {
    transform: translate3d(48vw, 102vh, 0);
  }

  to {
    transform: translate3d(45vw, -106vh, 0);
  }
}

.circle-container2:nth-child(48) .circle {
  animation-delay: 1095ms;
}

.circle-container2:nth-child(49) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-49;
  animation-duration: 36524ms;
  animation-delay: 5829ms;
}

@keyframes move-frames-49 {
  from {
    transform: translate3d(22vw, 102vh, 0);
  }

  to {
    transform: translate3d(51vw, -127vh, 0);
  }
}

.circle-container2:nth-child(49) .circle {
  animation-delay: 1664ms;
}

.circle-container2:nth-child(50) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-50;
  animation-duration: 32664ms;
  animation-delay: 20738ms;
}

@keyframes move-frames-50 {
  from {
    transform: translate3d(21vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -107vh, 0);
  }
}

.circle-container2:nth-child(50) .circle {
  animation-delay: 2185ms;
}

.circle-container2:nth-child(51) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-51;
  animation-duration: 35935ms;
  animation-delay: 34643ms;
}

@keyframes move-frames-51 {
  from {
    transform: translate3d(11vw, 105vh, 0);
  }

  to {
    transform: translate3d(55vw, -117vh, 0);
  }
}

.circle-container2:nth-child(51) .circle {
  animation-delay: 3170ms;
}

.circle-container2:nth-child(52) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-52;
  animation-duration: 35002ms;
  animation-delay: 9255ms;
}

@keyframes move-frames-52 {
  from {
    transform: translate3d(37vw, 107vh, 0);
  }

  to {
    transform: translate3d(27vw, -119vh, 0);
  }
}

.circle-container2:nth-child(52) .circle {
  animation-delay: 2869ms;
}

.circle-container2:nth-child(53) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-53;
  animation-duration: 31411ms;
  animation-delay: 5029ms;
}

@keyframes move-frames-53 {
  from {
    transform: translate3d(100vw, 109vh, 0);
  }

  to {
    transform: translate3d(3vw, -138vh, 0);
  }
}

.circle-container2:nth-child(53) .circle {
  animation-delay: 2710ms;
}

.circle-container2:nth-child(54) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-54;
  animation-duration: 34484ms;
  animation-delay: 3960ms;
}

@keyframes move-frames-54 {
  from {
    transform: translate3d(59vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -132vh, 0);
  }
}

.circle-container2:nth-child(54) .circle {
  animation-delay: 932ms;
}

.circle-container2:nth-child(55) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-55;
  animation-duration: 30870ms;
  animation-delay: 8766ms;
}

@keyframes move-frames-55 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(87vw, -137vh, 0);
  }
}

.circle-container2:nth-child(55) .circle {
  animation-delay: 2816ms;
}

.circle-container2:nth-child(56) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-56;
  animation-duration: 31465ms;
  animation-delay: 15049ms;
}

@keyframes move-frames-56 {
  from {
    transform: translate3d(11vw, 104vh, 0);
  }

  to {
    transform: translate3d(59vw, -106vh, 0);
  }
}

.circle-container2:nth-child(56) .circle {
  animation-delay: 502ms;
}

.circle-container2:nth-child(57) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-57;
  animation-duration: 34746ms;
  animation-delay: 24610ms;
}

@keyframes move-frames-57 {
  from {
    transform: translate3d(8vw, 106vh, 0);
  }

  to {
    transform: translate3d(6vw, -113vh, 0);
  }
}

.circle-container2:nth-child(57) .circle {
  animation-delay: 903ms;
}

.circle-container2:nth-child(58) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-58;
  animation-duration: 31745ms;
  animation-delay: 23189ms;
}

@keyframes move-frames-58 {
  from {
    transform: translate3d(49vw, 106vh, 0);
  }

  to {
    transform: translate3d(57vw, -128vh, 0);
  }
}

.circle-container2:nth-child(58) .circle {
  animation-delay: 3601ms;
}

.circle-container2:nth-child(59) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-59;
  animation-duration: 30816ms;
  animation-delay: 31745ms;
}

@keyframes move-frames-59 {
  from {
    transform: translate3d(59vw, 105vh, 0);
  }

  to {
    transform: translate3d(61vw, -123vh, 0);
  }
}

.circle-container2:nth-child(59) .circle {
  animation-delay: 1368ms;
}

.circle-container2:nth-child(60) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-60;
  animation-duration: 33647ms;
  animation-delay: 5961ms;
}

@keyframes move-frames-60 {
  from {
    transform: translate3d(88vw, 106vh, 0);
  }

  to {
    transform: translate3d(18vw, -126vh, 0);
  }
}

.circle-container2:nth-child(60) .circle {
  animation-delay: 679ms;
}

.circle-container2:nth-child(61) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-61;
  animation-duration: 31448ms;
  animation-delay: 29691ms;
}

@keyframes move-frames-61 {
  from {
    transform: translate3d(82vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -118vh, 0);
  }
}

.circle-container2:nth-child(61) .circle {
  animation-delay: 1833ms;
}

.circle-container2:nth-child(62) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-62;
  animation-duration: 28308ms;
  animation-delay: 19263ms;
}

@keyframes move-frames-62 {
  from {
    transform: translate3d(55vw, 104vh, 0);
  }

  to {
    transform: translate3d(86vw, -128vh, 0);
  }
}

.circle-container2:nth-child(62) .circle {
  animation-delay: 3453ms;
}

.circle-container2:nth-child(63) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-63;
  animation-duration: 30972ms;
  animation-delay: 3844ms;
}

@keyframes move-frames-63 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -107vh, 0);
  }
}

.circle-container2:nth-child(63) .circle {
  animation-delay: 626ms;
}

.circle-container2:nth-child(64) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-64;
  animation-duration: 33170ms;
  animation-delay: 144ms;
}

@keyframes move-frames-64 {
  from {
    transform: translate3d(41vw, 105vh, 0);
  }

  to {
    transform: translate3d(34vw, -111vh, 0);
  }
}

.circle-container2:nth-child(64) .circle {
  animation-delay: 1099ms;
}

.circle-container2:nth-child(65) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-65;
  animation-duration: 36461ms;
  animation-delay: 851ms;
}

@keyframes move-frames-65 {
  from {
    transform: translate3d(25vw, 103vh, 0);
  }

  to {
    transform: translate3d(79vw, -131vh, 0);
  }
}

.circle-container2:nth-child(65) .circle {
  animation-delay: 2827ms;
}

.circle-container2:nth-child(66) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-66;
  animation-duration: 32334ms;
  animation-delay: 21586ms;
}

@keyframes move-frames-66 {
  from {
    transform: translate3d(52vw, 107vh, 0);
  }

  to {
    transform: translate3d(44vw, -133vh, 0);
  }
}

.circle-container2:nth-child(66) .circle {
  animation-delay: 3780ms;
}

.circle-container2:nth-child(67) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-67;
  animation-duration: 34301ms;
  animation-delay: 34104ms;
}

@keyframes move-frames-67 {
  from {
    transform: translate3d(77vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -113vh, 0);
  }
}

.circle-container2:nth-child(67) .circle {
  animation-delay: 26ms;
}

.circle-container2:nth-child(68) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-68;
  animation-duration: 34599ms;
  animation-delay: 36245ms;
}

@keyframes move-frames-68 {
  from {
    transform: translate3d(74vw, 101vh, 0);
  }

  to {
    transform: translate3d(86vw, -103vh, 0);
  }
}

.circle-container2:nth-child(68) .circle {
  animation-delay: 2759ms;
}

.circle-container2:nth-child(69) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-69;
  animation-duration: 36460ms;
  animation-delay: 9061ms;
}

@keyframes move-frames-69 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(80vw, -122vh, 0);
  }
}

.circle-container2:nth-child(69) .circle {
  animation-delay: 2452ms;
}

.circle-container2:nth-child(70) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-70;
  animation-duration: 33436ms;
  animation-delay: 36789ms;
}

@keyframes move-frames-70 {
  from {
    transform: translate3d(74vw, 108vh, 0);
  }

  to {
    transform: translate3d(37vw, -124vh, 0);
  }
}

.circle-container2:nth-child(70) .circle {
  animation-delay: 3893ms;
}

.circle-container2:nth-child(71) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-71;
  animation-duration: 29878ms;
  animation-delay: 9226ms;
}

@keyframes move-frames-71 {
  from {
    transform: translate3d(62vw, 106vh, 0);
  }

  to {
    transform: translate3d(69vw, -135vh, 0);
  }
}

.circle-container2:nth-child(71) .circle {
  animation-delay: 2395ms;
}

.circle-container2:nth-child(72) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-72;
  animation-duration: 36655ms;
  animation-delay: 33632ms;
}

@keyframes move-frames-72 {
  from {
    transform: translate3d(41vw, 103vh, 0);
  }

  to {
    transform: translate3d(3vw, -117vh, 0);
  }
}

.circle-container2:nth-child(72) .circle {
  animation-delay: 2080ms;
}

.circle-container2:nth-child(73) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-73;
  animation-duration: 34472ms;
  animation-delay: 26092ms;
}

@keyframes move-frames-73 {
  from {
    transform: translate3d(12vw, 109vh, 0);
  }

  to {
    transform: translate3d(24vw, -134vh, 0);
  }
}

.circle-container2:nth-child(73) .circle {
  animation-delay: 313ms;
}

.circle-container2:nth-child(74) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-74;
  animation-duration: 31693ms;
  animation-delay: 27190ms;
}

@keyframes move-frames-74 {
  from {
    transform: translate3d(28vw, 102vh, 0);
  }

  to {
    transform: translate3d(71vw, -120vh, 0);
  }
}

.circle-container2:nth-child(74) .circle {
  animation-delay: 938ms;
}

.circle-container2:nth-child(75) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-75;
  animation-duration: 34065ms;
  animation-delay: 18116ms;
}

@keyframes move-frames-75 {
  from {
    transform: translate3d(42vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -107vh, 0);
  }
}

.circle-container2:nth-child(75) .circle {
  animation-delay: 588ms;
}

.circle-container2:nth-child(76) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-76;
  animation-duration: 30502ms;
  animation-delay: 26354ms;
}

@keyframes move-frames-76 {
  from {
    transform: translate3d(36vw, 104vh, 0);
  }

  to {
    transform: translate3d(89vw, -112vh, 0);
  }
}

.circle-container2:nth-child(76) .circle {
  animation-delay: 2460ms;
}

.circle-container2:nth-child(77) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-77;
  animation-duration: 31963ms;
  animation-delay: 23345ms;
}

@keyframes move-frames-77 {
  from {
    transform: translate3d(63vw, 105vh, 0);
  }

  to {
    transform: translate3d(94vw, -115vh, 0);
  }
}

.circle-container2:nth-child(77) .circle {
  animation-delay: 1193ms;
}

.circle-container2:nth-child(78) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-78;
  animation-duration: 30431ms;
  animation-delay: 22141ms;
}

@keyframes move-frames-78 {
  from {
    transform: translate3d(75vw, 109vh, 0);
  }

  to {
    transform: translate3d(16vw, -134vh, 0);
  }
}

.circle-container2:nth-child(78) .circle {
  animation-delay: 3693ms;
}

.circle-container2:nth-child(79) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-79;
  animation-duration: 36905ms;
  animation-delay: 20196ms;
}

@keyframes move-frames-79 {
  from {
    transform: translate3d(45vw, 102vh, 0);
  }

  to {
    transform: translate3d(67vw, -104vh, 0);
  }
}

.circle-container2:nth-child(79) .circle {
  animation-delay: 3138ms;
}

.circle-container2:nth-child(80) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-80;
  animation-duration: 33414ms;
  animation-delay: 28543ms;
}

@keyframes move-frames-80 {
  from {
    transform: translate3d(76vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -126vh, 0);
  }
}

.circle-container2:nth-child(80) .circle {
  animation-delay: 2014ms;
}

.circle-container2:nth-child(81) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-81;
  animation-duration: 34416ms;
  animation-delay: 26911ms;
}

@keyframes move-frames-81 {
  from {
    transform: translate3d(7vw, 103vh, 0);
  }

  to {
    transform: translate3d(67vw, -115vh, 0);
  }
}

.circle-container2:nth-child(81) .circle {
  animation-delay: 3870ms;
}

.circle-container2:nth-child(82) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-82;
  animation-duration: 29375ms;
  animation-delay: 36449ms;
}

@keyframes move-frames-82 {
  from {
    transform: translate3d(50vw, 109vh, 0);
  }

  to {
    transform: translate3d(87vw, -127vh, 0);
  }
}

.circle-container2:nth-child(82) .circle {
  animation-delay: 1207ms;
}

.circle-container2:nth-child(83) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-83;
  animation-duration: 34734ms;
  animation-delay: 2453ms;
}

@keyframes move-frames-83 {
  from {
    transform: translate3d(75vw, 108vh, 0);
  }

  to {
    transform: translate3d(23vw, -125vh, 0);
  }
}

.circle-container2:nth-child(83) .circle {
  animation-delay: 3365ms;
}

.circle-container2:nth-child(84) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-84;
  animation-duration: 32584ms;
  animation-delay: 22435ms;
}

@keyframes move-frames-84 {
  from {
    transform: translate3d(52vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -111vh, 0);
  }
}

.circle-container2:nth-child(84) .circle {
  animation-delay: 2065ms;
}

.circle-container2:nth-child(85) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-85;
  animation-duration: 30914ms;
  animation-delay: 4700ms;
}

@keyframes move-frames-85 {
  from {
    transform: translate3d(31vw, 103vh, 0);
  }

  to {
    transform: translate3d(29vw, -121vh, 0);
  }
}

.circle-container2:nth-child(85) .circle {
  animation-delay: 471ms;
}

.circle-container2:nth-child(86) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-86;
  animation-duration: 34295ms;
  animation-delay: 36675ms;
}

@keyframes move-frames-86 {
  from {
    transform: translate3d(78vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -117vh, 0);
  }
}

.circle-container2:nth-child(86) .circle {
  animation-delay: 2669ms;
}

.circle-container2:nth-child(87) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-87;
  animation-duration: 31904ms;
  animation-delay: 7428ms;
}

@keyframes move-frames-87 {
  from {
    transform: translate3d(30vw, 109vh, 0);
  }

  to {
    transform: translate3d(70vw, -132vh, 0);
  }
}

.circle-container2:nth-child(87) .circle {
  animation-delay: 2520ms;
}

.circle-container2:nth-child(88) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-88;
  animation-duration: 32334ms;
  animation-delay: 17801ms;
}

@keyframes move-frames-88 {
  from {
    transform: translate3d(39vw, 101vh, 0);
  }

  to {
    transform: translate3d(61vw, -107vh, 0);
  }
}

.circle-container2:nth-child(88) .circle {
  animation-delay: 2175ms;
}

.circle-container2:nth-child(89) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-89;
  animation-duration: 34126ms;
  animation-delay: 30329ms;
}

@keyframes move-frames-89 {
  from {
    transform: translate3d(71vw, 103vh, 0);
  }

  to {
    transform: translate3d(44vw, -125vh, 0);
  }
}

.circle-container2:nth-child(89) .circle {
  animation-delay: 979ms;
}

.circle-container2:nth-child(90) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-90;
  animation-duration: 30221ms;
  animation-delay: 27967ms;
}

@keyframes move-frames-90 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(43vw, -131vh, 0);
  }
}

.circle-container2:nth-child(90) .circle {
  animation-delay: 3340ms;
}

.circle-container2:nth-child(91) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-91;
  animation-duration: 33856ms;
  animation-delay: 22877ms;
}

@keyframes move-frames-91 {
  from {
    transform: translate3d(38vw, 101vh, 0);
  }

  to {
    transform: translate3d(50vw, -109vh, 0);
  }
}

.circle-container2:nth-child(91) .circle {
  animation-delay: 2601ms;
}

.circle-container2:nth-child(92) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-92;
  animation-duration: 33790ms;
  animation-delay: 25047ms;
}

@keyframes move-frames-92 {
  from {
    transform: translate3d(28vw, 105vh, 0);
  }

  to {
    transform: translate3d(67vw, -109vh, 0);
  }
}

.circle-container2:nth-child(92) .circle {
  animation-delay: 995ms;
}

.circle-container2:nth-child(93) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-93;
  animation-duration: 32289ms;
  animation-delay: 8081ms;
}

@keyframes move-frames-93 {
  from {
    transform: translate3d(7vw, 109vh, 0);
  }

  to {
    transform: translate3d(25vw, -129vh, 0);
  }
}

.circle-container2:nth-child(93) .circle {
  animation-delay: 1297ms;
}

.circle-container2:nth-child(94) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-94;
  animation-duration: 36464ms;
  animation-delay: 35818ms;
}

@keyframes move-frames-94 {
  from {
    transform: translate3d(98vw, 102vh, 0);
  }

  to {
    transform: translate3d(73vw, -104vh, 0);
  }
}

.circle-container2:nth-child(94) .circle {
  animation-delay: 1388ms;
}

.circle-container2:nth-child(95) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-95;
  animation-duration: 35765ms;
  animation-delay: 21102ms;
}

@keyframes move-frames-95 {
  from {
    transform: translate3d(39vw, 104vh, 0);
  }

  to {
    transform: translate3d(36vw, -111vh, 0);
  }
}

.circle-container2:nth-child(95) .circle {
  animation-delay: 2921ms;
}

.circle-container2:nth-child(96) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-96;
  animation-duration: 30502ms;
  animation-delay: 27214ms;
}

@keyframes move-frames-96 {
  from {
    transform: translate3d(15vw, 108vh, 0);
  }

  to {
    transform: translate3d(13vw, -136vh, 0);
  }
}

.circle-container2:nth-child(96) .circle {
  animation-delay: 3485ms;
}

.circle-container2:nth-child(97) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-97;
  animation-duration: 35275ms;
  animation-delay: 12588ms;
}

@keyframes move-frames-97 {
  from {
    transform: translate3d(42vw, 109vh, 0);
  }

  to {
    transform: translate3d(11vw, -122vh, 0);
  }
}

.circle-container2:nth-child(97) .circle {
  animation-delay: 2892ms;
}

.circle-container2:nth-child(98) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-98;
  animation-duration: 35993ms;
  animation-delay: 27568ms;
}

@keyframes move-frames-98 {
  from {
    transform: translate3d(18vw, 101vh, 0);
  }

  to {
    transform: translate3d(33vw, -119vh, 0);
  }
}

.circle-container2:nth-child(98) .circle {
  animation-delay: 74ms;
}

.circle-container2:nth-child(99) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-99;
  animation-duration: 28213ms;
  animation-delay: 33567ms;
}

@keyframes move-frames-99 {
  from {
    transform: translate3d(95vw, 106vh, 0);
  }

  to {
    transform: translate3d(79vw, -135vh, 0);
  }
}

.circle-container2:nth-child(99) .circle {
  animation-delay: 198ms;
}

.circle-container2:nth-child(100) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-100;
  animation-duration: 29405ms;
  animation-delay: 30490ms;
}

@keyframes move-frames-100 {
  from {
    transform: translate3d(5vw, 103vh, 0);
  }

  to {
    transform: translate3d(5vw, -110vh, 0);
  }
}

.circle-container2:nth-child(100) .circle {
  animation-delay: 3981ms;
}

.circle-container2:nth-child(101) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-101;
  animation-duration: 29968ms;
  animation-delay: 9125ms;
}

@keyframes move-frames-101 {
  from {
    transform: translate3d(85vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -110vh, 0);
  }
}

.circle-container2:nth-child(101) .circle {
  animation-delay: 2201ms;
}

.circle-container2:nth-child(102) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-102;
  animation-duration: 28916ms;
  animation-delay: 22698ms;
}

@keyframes move-frames-102 {
  from {
    transform: translate3d(37vw, 110vh, 0);
  }

  to {
    transform: translate3d(20vw, -120vh, 0);
  }
}

.circle-container2:nth-child(102) .circle {
  animation-delay: 3872ms;
}

.circle-container2:nth-child(103) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-103;
  animation-duration: 29503ms;
  animation-delay: 21791ms;
}

@keyframes move-frames-103 {
  from {
    transform: translate3d(86vw, 104vh, 0);
  }

  to {
    transform: translate3d(2vw, -134vh, 0);
  }
}

.circle-container2:nth-child(103) .circle {
  animation-delay: 3570ms;
}

.circle-container2:nth-child(104) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-104;
  animation-duration: 30734ms;
  animation-delay: 30465ms;
}

@keyframes move-frames-104 {
  from {
    transform: translate3d(98vw, 101vh, 0);
  }

  to {
    transform: translate3d(35vw, -109vh, 0);
  }
}

.circle-container2:nth-child(104) .circle {
  animation-delay: 3690ms;
}

.circle-container2:nth-child(105) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-105;
  animation-duration: 30204ms;
  animation-delay: 14424ms;
}

@keyframes move-frames-105 {
  from {
    transform: translate3d(25vw, 109vh, 0);
  }

  to {
    transform: translate3d(71vw, -126vh, 0);
  }
}

.circle-container2:nth-child(105) .circle {
  animation-delay: 3170ms;
}

.circle-container2:nth-child(106) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-106;
  animation-duration: 32148ms;
  animation-delay: 19165ms;
}

@keyframes move-frames-106 {
  from {
    transform: translate3d(84vw, 105vh, 0);
  }

  to {
    transform: translate3d(52vw, -128vh, 0);
  }
}

.circle-container2:nth-child(106) .circle {
  animation-delay: 3842ms;
}

.circle-container2:nth-child(107) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-107;
  animation-duration: 36943ms;
  animation-delay: 12405ms;
}

@keyframes move-frames-107 {
  from {
    transform: translate3d(16vw, 110vh, 0);
  }

  to {
    transform: translate3d(1vw, -112vh, 0);
  }
}

.circle-container2:nth-child(107) .circle {
  animation-delay: 3906ms;
}

.circle-container2:nth-child(108) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-108;
  animation-duration: 35467ms;
  animation-delay: 32037ms;
}

@keyframes move-frames-108 {
  from {
    transform: translate3d(56vw, 110vh, 0);
  }

  to {
    transform: translate3d(93vw, -118vh, 0);
  }
}

.circle-container2:nth-child(108) .circle {
  animation-delay: 1682ms;
}

.circle-container2:nth-child(109) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-109;
  animation-duration: 32289ms;
  animation-delay: 25940ms;
}

@keyframes move-frames-109 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(78vw, -133vh, 0);
  }
}

.circle-container2:nth-child(109) .circle {
  animation-delay: 859ms;
}

.circle-container2:nth-child(110) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-110;
  animation-duration: 33249ms;
  animation-delay: 11206ms;
}

@keyframes move-frames-110 {
  from {
    transform: translate3d(78vw, 107vh, 0);
  }

  to {
    transform: translate3d(42vw, -128vh, 0);
  }
}

.circle-container2:nth-child(110) .circle {
  animation-delay: 1543ms;
}

.circle-container2:nth-child(111) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-111;
  animation-duration: 32235ms;
  animation-delay: 22721ms;
}

@keyframes move-frames-111 {
  from {
    transform: translate3d(93vw, 107vh, 0);
  }

  to {
    transform: translate3d(72vw, -129vh, 0);
  }
}

.circle-container2:nth-child(111) .circle {
  animation-delay: 926ms;
}

.circle-container2:nth-child(112) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-112;
  animation-duration: 36584ms;
  animation-delay: 17442ms;
}

@keyframes move-frames-112 {
  from {
    transform: translate3d(66vw, 103vh, 0);
  }

  to {
    transform: translate3d(84vw, -128vh, 0);
  }
}

.circle-container2:nth-child(112) .circle {
  animation-delay: 3359ms;
}

.circle-container2:nth-child(113) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-113;
  animation-duration: 31260ms;
  animation-delay: 8370ms;
}

@keyframes move-frames-113 {
  from {
    transform: translate3d(46vw, 108vh, 0);
  }

  to {
    transform: translate3d(42vw, -132vh, 0);
  }
}

.circle-container2:nth-child(113) .circle {
  animation-delay: 1133ms;
}

.circle-container2:nth-child(114) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-114;
  animation-duration: 29389ms;
  animation-delay: 5792ms;
}

@keyframes move-frames-114 {
  from {
    transform: translate3d(3vw, 106vh, 0);
  }

  to {
    transform: translate3d(58vw, -126vh, 0);
  }
}

.circle-container2:nth-child(114) .circle {
  animation-delay: 3600ms;
}

.circle-container2:nth-child(115) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-115;
  animation-duration: 28611ms;
  animation-delay: 21873ms;
}

@keyframes move-frames-115 {
  from {
    transform: translate3d(26vw, 107vh, 0);
  }

  to {
    transform: translate3d(20vw, -123vh, 0);
  }
}

.circle-container2:nth-child(115) .circle {
  animation-delay: 1609ms;
}

.circle-container2:nth-child(116) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-116;
  animation-duration: 35599ms;
  animation-delay: 734ms;
}

@keyframes move-frames-116 {
  from {
    transform: translate3d(86vw, 107vh, 0);
  }

  to {
    transform: translate3d(41vw, -133vh, 0);
  }
}

.circle-container2:nth-child(116) .circle {
  animation-delay: 1710ms;
}

.circle-container2:nth-child(117) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-117;
  animation-duration: 35085ms;
  animation-delay: 32674ms;
}

@keyframes move-frames-117 {
  from {
    transform: translate3d(65vw, 107vh, 0);
  }

  to {
    transform: translate3d(86vw, -111vh, 0);
  }
}

.circle-container2:nth-child(117) .circle {
  animation-delay: 391ms;
}

.circle-container2:nth-child(118) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-118;
  animation-duration: 28426ms;
  animation-delay: 13214ms;
}

@keyframes move-frames-118 {
  from {
    transform: translate3d(88vw, 109vh, 0);
  }

  to {
    transform: translate3d(39vw, -131vh, 0);
  }
}

.circle-container2:nth-child(118) .circle {
  animation-delay: 2889ms;
}

.circle-container2:nth-child(119) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-119;
  animation-duration: 31179ms;
  animation-delay: 1360ms;
}

@keyframes move-frames-119 {
  from {
    transform: translate3d(17vw, 109vh, 0);
  }

  to {
    transform: translate3d(86vw, -118vh, 0);
  }
}

.circle-container2:nth-child(119) .circle {
  animation-delay: 1297ms;
}

.circle-container2:nth-child(120) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-120;
  animation-duration: 35514ms;
  animation-delay: 23725ms;
}

@keyframes move-frames-120 {
  from {
    transform: translate3d(70vw, 106vh, 0);
  }

  to {
    transform: translate3d(37vw, -122vh, 0);
  }
}

.circle-container2:nth-child(120) .circle {
  animation-delay: 1030ms;
}

.circle-container2:nth-child(121) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-121;
  animation-duration: 32154ms;
  animation-delay: 30916ms;
}

@keyframes move-frames-121 {
  from {
    transform: translate3d(69vw, 109vh, 0);
  }

  to {
    transform: translate3d(90vw, -117vh, 0);
  }
}

.circle-container2:nth-child(121) .circle {
  animation-delay: 2214ms;
}

.circle-container2:nth-child(122) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-122;
  animation-duration: 35331ms;
  animation-delay: 32463ms;
}

@keyframes move-frames-122 {
  from {
    transform: translate3d(47vw, 108vh, 0);
  }

  to {
    transform: translate3d(51vw, -125vh, 0);
  }
}

.circle-container2:nth-child(122) .circle {
  animation-delay: 3707ms;
}

.circle-container2:nth-child(123) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-123;
  animation-duration: 28684ms;
  animation-delay: 36500ms;
}

@keyframes move-frames-123 {
  from {
    transform: translate3d(44vw, 109vh, 0);
  }

  to {
    transform: translate3d(31vw, -122vh, 0);
  }
}

.circle-container2:nth-child(123) .circle {
  animation-delay: 447ms;
}

.circle-container2:nth-child(124) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-124;
  animation-duration: 36090ms;
  animation-delay: 7067ms;
}

@keyframes move-frames-124 {
  from {
    transform: translate3d(74vw, 107vh, 0);
  }

  to {
    transform: translate3d(89vw, -111vh, 0);
  }
}

.circle-container2:nth-child(124) .circle {
  animation-delay: 2240ms;
}

.circle-container2:nth-child(125) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-125;
  animation-duration: 28814ms;
  animation-delay: 9308ms;
}

@keyframes move-frames-125 {
  from {
    transform: translate3d(69vw, 108vh, 0);
  }

  to {
    transform: translate3d(78vw, -122vh, 0);
  }
}

.circle-container2:nth-child(125) .circle {
  animation-delay: 69ms;
}

.circle-container2:nth-child(126) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-126;
  animation-duration: 29080ms;
  animation-delay: 28145ms;
}

@keyframes move-frames-126 {
  from {
    transform: translate3d(99vw, 105vh, 0);
  }

  to {
    transform: translate3d(42vw, -112vh, 0);
  }
}

.circle-container2:nth-child(126) .circle {
  animation-delay: 7ms;
}

.circle-container2:nth-child(127) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-127;
  animation-duration: 28660ms;
  animation-delay: 33412ms;
}

@keyframes move-frames-127 {
  from {
    transform: translate3d(69vw, 102vh, 0);
  }

  to {
    transform: translate3d(23vw, -111vh, 0);
  }
}

.circle-container2:nth-child(127) .circle {
  animation-delay: 2882ms;
}

.circle-container2:nth-child(128) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-128;
  animation-duration: 31174ms;
  animation-delay: 24214ms;
}

@keyframes move-frames-128 {
  from {
    transform: translate3d(7vw, 106vh, 0);
  }

  to {
    transform: translate3d(23vw, -115vh, 0);
  }
}

.circle-container2:nth-child(128) .circle {
  animation-delay: 3082ms;
}

.circle-container2:nth-child(129) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-129;
  animation-duration: 32171ms;
  animation-delay: 34435ms;
}

@keyframes move-frames-129 {
  from {
    transform: translate3d(31vw, 101vh, 0);
  }

  to {
    transform: translate3d(34vw, -129vh, 0);
  }
}

.circle-container2:nth-child(129) .circle {
  animation-delay: 872ms;
}

.circle-container2:nth-child(130) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-130;
  animation-duration: 36636ms;
  animation-delay: 5949ms;
}

@keyframes move-frames-130 {
  from {
    transform: translate3d(46vw, 105vh, 0);
  }

  to {
    transform: translate3d(5vw, -130vh, 0);
  }
}

.circle-container2:nth-child(130) .circle {
  animation-delay: 2219ms;
}

.circle-container2:nth-child(131) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-131;
  animation-duration: 31452ms;
  animation-delay: 9541ms;
}

@keyframes move-frames-131 {
  from {
    transform: translate3d(71vw, 107vh, 0);
  }

  to {
    transform: translate3d(25vw, -126vh, 0);
  }
}

.circle-container2:nth-child(131) .circle {
  animation-delay: 421ms;
}

.circle-container2:nth-child(132) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-132;
  animation-duration: 29409ms;
  animation-delay: 5131ms;
}

@keyframes move-frames-132 {
  from {
    transform: translate3d(96vw, 110vh, 0);
  }

  to {
    transform: translate3d(16vw, -119vh, 0);
  }
}

.circle-container2:nth-child(132) .circle {
  animation-delay: 2650ms;
}

.circle-container2:nth-child(133) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-133;
  animation-duration: 34135ms;
  animation-delay: 22884ms;
}

@keyframes move-frames-133 {
  from {
    transform: translate3d(38vw, 103vh, 0);
  }

  to {
    transform: translate3d(9vw, -122vh, 0);
  }
}

.circle-container2:nth-child(133) .circle {
  animation-delay: 2856ms;
}

.circle-container2:nth-child(134) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-134;
  animation-duration: 28153ms;
  animation-delay: 13261ms;
}

@keyframes move-frames-134 {
  from {
    transform: translate3d(100vw, 105vh, 0);
  }

  to {
    transform: translate3d(10vw, -133vh, 0);
  }
}

.circle-container2:nth-child(134) .circle {
  animation-delay: 3709ms;
}

.circle-container2:nth-child(135) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-135;
  animation-duration: 32127ms;
  animation-delay: 34353ms;
}

@keyframes move-frames-135 {
  from {
    transform: translate3d(5vw, 110vh, 0);
  }

  to {
    transform: translate3d(97vw, -121vh, 0);
  }
}

.circle-container2:nth-child(135) .circle {
  animation-delay: 799ms;
}

.circle-container2:nth-child(136) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-136;
  animation-duration: 29737ms;
  animation-delay: 25429ms;
}

@keyframes move-frames-136 {
  from {
    transform: translate3d(61vw, 108vh, 0);
  }

  to {
    transform: translate3d(97vw, -131vh, 0);
  }
}

.circle-container2:nth-child(136) .circle {
  animation-delay: 2671ms;
}

.circle-container2:nth-child(137) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-137;
  animation-duration: 34817ms;
  animation-delay: 16326ms;
}

@keyframes move-frames-137 {
  from {
    transform: translate3d(13vw, 109vh, 0);
  }

  to {
    transform: translate3d(63vw, -132vh, 0);
  }
}

.circle-container2:nth-child(137) .circle {
  animation-delay: 1927ms;
}

.circle-container2:nth-child(138) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-138;
  animation-duration: 36324ms;
  animation-delay: 3871ms;
}

@keyframes move-frames-138 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(5vw, -119vh, 0);
  }
}

.circle-container2:nth-child(138) .circle {
  animation-delay: 2589ms;
}

.circle-container2:nth-child(139) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-139;
  animation-duration: 33537ms;
  animation-delay: 11929ms;
}

@keyframes move-frames-139 {
  from {
    transform: translate3d(59vw, 109vh, 0);
  }

  to {
    transform: translate3d(9vw, -133vh, 0);
  }
}

.circle-container2:nth-child(139) .circle {
  animation-delay: 424ms;
}

.circle-container2:nth-child(140) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-140;
  animation-duration: 34662ms;
  animation-delay: 21605ms;
}

@keyframes move-frames-140 {
  from {
    transform: translate3d(84vw, 106vh, 0);
  }

  to {
    transform: translate3d(15vw, -136vh, 0);
  }
}

.circle-container2:nth-child(140) .circle {
  animation-delay: 3430ms;
}

.circle-container2:nth-child(141) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-141;
  animation-duration: 31241ms;
  animation-delay: 15328ms;
}

@keyframes move-frames-141 {
  from {
    transform: translate3d(30vw, 101vh, 0);
  }

  to {
    transform: translate3d(42vw, -107vh, 0);
  }
}

.circle-container2:nth-child(141) .circle {
  animation-delay: 632ms;
}

.circle-container2:nth-child(142) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-142;
  animation-duration: 28931ms;
  animation-delay: 10856ms;
}

@keyframes move-frames-142 {
  from {
    transform: translate3d(66vw, 105vh, 0);
  }

  to {
    transform: translate3d(26vw, -128vh, 0);
  }
}

.circle-container2:nth-child(142) .circle {
  animation-delay: 507ms;
}

.circle-container2:nth-child(143) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-143;
  animation-duration: 35135ms;
  animation-delay: 5486ms;
}

@keyframes move-frames-143 {
  from {
    transform: translate3d(80vw, 105vh, 0);
  }

  to {
    transform: translate3d(28vw, -128vh, 0);
  }
}

.circle-container2:nth-child(143) .circle {
  animation-delay: 1790ms;
}

.circle-container2:nth-child(144) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-144;
  animation-duration: 34630ms;
  animation-delay: 19793ms;
}

@keyframes move-frames-144 {
  from {
    transform: translate3d(68vw, 108vh, 0);
  }

  to {
    transform: translate3d(66vw, -130vh, 0);
  }
}

.circle-container2:nth-child(144) .circle {
  animation-delay: 1756ms;
}

.circle-container2:nth-child(145) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-145;
  animation-duration: 29462ms;
  animation-delay: 25517ms;
}

@keyframes move-frames-145 {
  from {
    transform: translate3d(49vw, 104vh, 0);
  }

  to {
    transform: translate3d(73vw, -124vh, 0);
  }
}

.circle-container2:nth-child(145) .circle {
  animation-delay: 3803ms;
}

.circle-container2:nth-child(146) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-146;
  animation-duration: 35256ms;
  animation-delay: 9570ms;
}

@keyframes move-frames-146 {
  from {
    transform: translate3d(15vw, 106vh, 0);
  }

  to {
    transform: translate3d(29vw, -117vh, 0);
  }
}

.circle-container2:nth-child(146) .circle {
  animation-delay: 3970ms;
}

.circle-container2:nth-child(147) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-147;
  animation-duration: 33627ms;
  animation-delay: 36109ms;
}

@keyframes move-frames-147 {
  from {
    transform: translate3d(91vw, 110vh, 0);
  }

  to {
    transform: translate3d(67vw, -123vh, 0);
  }
}

.circle-container2:nth-child(147) .circle {
  animation-delay: 2714ms;
}

.circle-container2:nth-child(148) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-148;
  animation-duration: 34801ms;
  animation-delay: 743ms;
}

@keyframes move-frames-148 {
  from {
    transform: translate3d(82vw, 102vh, 0);
  }

  to {
    transform: translate3d(43vw, -115vh, 0);
  }
}

.circle-container2:nth-child(148) .circle {
  animation-delay: 2064ms;
}

.circle-container2:nth-child(149) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-149;
  animation-duration: 33669ms;
  animation-delay: 32275ms;
}

@keyframes move-frames-149 {
  from {
    transform: translate3d(45vw, 101vh, 0);
  }

  to {
    transform: translate3d(23vw, -103vh, 0);
  }
}

.circle-container2:nth-child(149) .circle {
  animation-delay: 1628ms;
}

.circle-container2:nth-child(150) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-150;
  animation-duration: 34795ms;
  animation-delay: 4749ms;
}

@keyframes move-frames-150 {
  from {
    transform: translate3d(14vw, 110vh, 0);
  }

  to {
    transform: translate3d(73vw, -123vh, 0);
  }
}

.circle-container2:nth-child(150) .circle {
  animation-delay: 2055ms;
}

.circle-container2:nth-child(151) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-151;
  animation-duration: 33885ms;
  animation-delay: 29883ms;
}

@keyframes move-frames-151 {
  from {
    transform: translate3d(4vw, 103vh, 0);
  }

  to {
    transform: translate3d(51vw, -110vh, 0);
  }
}

.circle-container2:nth-child(151) .circle {
  animation-delay: 2272ms;
}

.circle-container2:nth-child(152) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-152;
  animation-duration: 36908ms;
  animation-delay: 36920ms;
}

@keyframes move-frames-152 {
  from {
    transform: translate3d(30vw, 102vh, 0);
  }

  to {
    transform: translate3d(99vw, -124vh, 0);
  }
}

.circle-container2:nth-child(152) .circle {
  animation-delay: 778ms;
}

.circle-container2:nth-child(153) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-153;
  animation-duration: 28352ms;
  animation-delay: 7520ms;
}

@keyframes move-frames-153 {
  from {
    transform: translate3d(25vw, 107vh, 0);
  }

  to {
    transform: translate3d(93vw, -111vh, 0);
  }
}

.circle-container2:nth-child(153) .circle {
  animation-delay: 720ms;
}

.circle-container2:nth-child(154) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-154;
  animation-duration: 36188ms;
  animation-delay: 2989ms;
}

@keyframes move-frames-154 {
  from {
    transform: translate3d(81vw, 102vh, 0);
  }

  to {
    transform: translate3d(20vw, -126vh, 0);
  }
}

.circle-container2:nth-child(154) .circle {
  animation-delay: 527ms;
}

.circle-container2:nth-child(155) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-155;
  animation-duration: 32707ms;
  animation-delay: 19860ms;
}

@keyframes move-frames-155 {
  from {
    transform: translate3d(99vw, 108vh, 0);
  }

  to {
    transform: translate3d(28vw, -133vh, 0);
  }
}

.circle-container2:nth-child(155) .circle {
  animation-delay: 3559ms;
}

.circle-container2:nth-child(156) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-156;
  animation-duration: 36634ms;
  animation-delay: 5748ms;
}

@keyframes move-frames-156 {
  from {
    transform: translate3d(16vw, 109vh, 0);
  }

  to {
    transform: translate3d(75vw, -138vh, 0);
  }
}

.circle-container2:nth-child(156) .circle {
  animation-delay: 3847ms;
}

.circle-container2:nth-child(157) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-157;
  animation-duration: 34146ms;
  animation-delay: 1383ms;
}

@keyframes move-frames-157 {
  from {
    transform: translate3d(14vw, 107vh, 0);
  }

  to {
    transform: translate3d(5vw, -109vh, 0);
  }
}

.circle-container2:nth-child(157) .circle {
  animation-delay: 3120ms;
}

.circle-container2:nth-child(158) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-158;
  animation-duration: 34661ms;
  animation-delay: 24156ms;
}

@keyframes move-frames-158 {
  from {
    transform: translate3d(13vw, 106vh, 0);
  }

  to {
    transform: translate3d(80vw, -108vh, 0);
  }
}

.circle-container2:nth-child(158) .circle {
  animation-delay: 3187ms;
}

.circle-container2:nth-child(159) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-159;
  animation-duration: 31555ms;
  animation-delay: 35581ms;
}

@keyframes move-frames-159 {
  from {
    transform: translate3d(4vw, 108vh, 0);
  }

  to {
    transform: translate3d(4vw, -124vh, 0);
  }
}

.circle-container2:nth-child(159) .circle {
  animation-delay: 2664ms;
}

.circle-container2:nth-child(160) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-160;
  animation-duration: 34063ms;
  animation-delay: 36698ms;
}

@keyframes move-frames-160 {
  from {
    transform: translate3d(19vw, 104vh, 0);
  }

  to {
    transform: translate3d(10vw, -125vh, 0);
  }
}

.circle-container2:nth-child(160) .circle {
  animation-delay: 3848ms;
}

.circle-container2:nth-child(161) {
  width: 5px;
  height: 5px;
  animation-name: move-frames-161;
  animation-duration: 33296ms;
  animation-delay: 31319ms;
}

@keyframes move-frames-161 {
  from {
    transform: translate3d(9vw, 107vh, 0);
  }

  to {
    transform: translate3d(78vw, -120vh, 0);
  }
}

.circle-container2:nth-child(161) .circle {
  animation-delay: 2432ms;
}

.circle-container2:nth-child(162) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-162;
  animation-duration: 35812ms;
  animation-delay: 14297ms;
}

@keyframes move-frames-162 {
  from {
    transform: translate3d(74vw, 105vh, 0);
  }

  to {
    transform: translate3d(25vw, -121vh, 0);
  }
}

.circle-container2:nth-child(162) .circle {
  animation-delay: 3380ms;
}

.circle-container2:nth-child(163) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-163;
  animation-duration: 34820ms;
  animation-delay: 26445ms;
}

@keyframes move-frames-163 {
  from {
    transform: translate3d(10vw, 101vh, 0);
  }

  to {
    transform: translate3d(95vw, -118vh, 0);
  }
}

.circle-container2:nth-child(163) .circle {
  animation-delay: 1034ms;
}

.circle-container2:nth-child(164) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-164;
  animation-duration: 29794ms;
  animation-delay: 16383ms;
}

@keyframes move-frames-164 {
  from {
    transform: translate3d(27vw, 101vh, 0);
  }

  to {
    transform: translate3d(43vw, -116vh, 0);
  }
}

.circle-container2:nth-child(164) .circle {
  animation-delay: 927ms;
}

.circle-container2:nth-child(165) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-165;
  animation-duration: 34792ms;
  animation-delay: 30428ms;
}

@keyframes move-frames-165 {
  from {
    transform: translate3d(77vw, 107vh, 0);
  }

  to {
    transform: translate3d(15vw, -121vh, 0);
  }
}

.circle-container2:nth-child(165) .circle {
  animation-delay: 2331ms;
}

.circle-container2:nth-child(166) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-166;
  animation-duration: 31779ms;
  animation-delay: 8039ms;
}

@keyframes move-frames-166 {
  from {
    transform: translate3d(55vw, 109vh, 0);
  }

  to {
    transform: translate3d(36vw, -133vh, 0);
  }
}

.circle-container2:nth-child(166) .circle {
  animation-delay: 1501ms;
}

.circle-container2:nth-child(167) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-167;
  animation-duration: 33558ms;
  animation-delay: 18201ms;
}

@keyframes move-frames-167 {
  from {
    transform: translate3d(80vw, 110vh, 0);
  }

  to {
    transform: translate3d(35vw, -113vh, 0);
  }
}

.circle-container2:nth-child(167) .circle {
  animation-delay: 3387ms;
}

.circle-container2:nth-child(168) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-168;
  animation-duration: 33625ms;
  animation-delay: 21179ms;
}

@keyframes move-frames-168 {
  from {
    transform: translate3d(32vw, 108vh, 0);
  }

  to {
    transform: translate3d(2vw, -130vh, 0);
  }
}

.circle-container2:nth-child(168) .circle {
  animation-delay: 1929ms;
}

.circle-container2:nth-child(169) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-169;
  animation-duration: 35307ms;
  animation-delay: 29480ms;
}

@keyframes move-frames-169 {
  from {
    transform: translate3d(31vw, 102vh, 0);
  }

  to {
    transform: translate3d(61vw, -130vh, 0);
  }
}

.circle-container2:nth-child(169) .circle {
  animation-delay: 1027ms;
}

.circle-container2:nth-child(170) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-170;
  animation-duration: 31199ms;
  animation-delay: 28197ms;
}

@keyframes move-frames-170 {
  from {
    transform: translate3d(75vw, 105vh, 0);
  }

  to {
    transform: translate3d(16vw, -127vh, 0);
  }
}

.circle-container2:nth-child(170) .circle {
  animation-delay: 3401ms;
}

.circle-container2:nth-child(171) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-171;
  animation-duration: 32710ms;
  animation-delay: 25985ms;
}

@keyframes move-frames-171 {
  from {
    transform: translate3d(40vw, 108vh, 0);
  }

  to {
    transform: translate3d(16vw, -129vh, 0);
  }
}

.circle-container2:nth-child(171) .circle {
  animation-delay: 3100ms;
}

.circle-container2:nth-child(172) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-172;
  animation-duration: 36094ms;
  animation-delay: 22192ms;
}

@keyframes move-frames-172 {
  from {
    transform: translate3d(70vw, 107vh, 0);
  }

  to {
    transform: translate3d(33vw, -133vh, 0);
  }
}

.circle-container2:nth-child(172) .circle {
  animation-delay: 3260ms;
}

.circle-container2:nth-child(173) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-173;
  animation-duration: 28325ms;
  animation-delay: 14848ms;
}

@keyframes move-frames-173 {
  from {
    transform: translate3d(39vw, 107vh, 0);
  }

  to {
    transform: translate3d(55vw, -137vh, 0);
  }
}

.circle-container2:nth-child(173) .circle {
  animation-delay: 1544ms;
}

.circle-container2:nth-child(174) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-174;
  animation-duration: 31498ms;
  animation-delay: 34860ms;
}

@keyframes move-frames-174 {
  from {
    transform: translate3d(82vw, 105vh, 0);
  }

  to {
    transform: translate3d(68vw, -110vh, 0);
  }
}

.circle-container2:nth-child(174) .circle {
  animation-delay: 1419ms;
}

.circle-container2:nth-child(175) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-175;
  animation-duration: 32296ms;
  animation-delay: 12310ms;
}

@keyframes move-frames-175 {
  from {
    transform: translate3d(48vw, 105vh, 0);
  }

  to {
    transform: translate3d(72vw, -123vh, 0);
  }
}

.circle-container2:nth-child(175) .circle {
  animation-delay: 89ms;
}

.circle-container2:nth-child(176) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-176;
  animation-duration: 31695ms;
  animation-delay: 24667ms;
}

@keyframes move-frames-176 {
  from {
    transform: translate3d(22vw, 109vh, 0);
  }

  to {
    transform: translate3d(4vw, -126vh, 0);
  }
}

.circle-container2:nth-child(176) .circle {
  animation-delay: 3187ms;
}

.circle-container2:nth-child(177) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-177;
  animation-duration: 31664ms;
  animation-delay: 18211ms;
}

@keyframes move-frames-177 {
  from {
    transform: translate3d(49vw, 101vh, 0);
  }

  to {
    transform: translate3d(29vw, -125vh, 0);
  }
}

.circle-container2:nth-child(177) .circle {
  animation-delay: 2819ms;
}

.circle-container2:nth-child(178) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-178;
  animation-duration: 28392ms;
  animation-delay: 34567ms;
}

@keyframes move-frames-178 {
  from {
    transform: translate3d(51vw, 110vh, 0);
  }

  to {
    transform: translate3d(100vw, -131vh, 0);
  }
}

.circle-container2:nth-child(178) .circle {
  animation-delay: 2722ms;
}

.circle-container2:nth-child(179) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-179;
  animation-duration: 30963ms;
  animation-delay: 24078ms;
}

@keyframes move-frames-179 {
  from {
    transform: translate3d(52vw, 108vh, 0);
  }

  to {
    transform: translate3d(41vw, -136vh, 0);
  }
}

.circle-container2:nth-child(179) .circle {
  animation-delay: 1139ms;
}

.circle-container2:nth-child(180) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-180;
  animation-duration: 33541ms;
  animation-delay: 17296ms;
}

@keyframes move-frames-180 {
  from {
    transform: translate3d(57vw, 102vh, 0);
  }

  to {
    transform: translate3d(58vw, -132vh, 0);
  }
}

.circle-container2:nth-child(180) .circle {
  animation-delay: 2123ms;
}

.circle-container2:nth-child(181) {
  width: 3px;
  height: 3px;
  animation-name: move-frames-181;
  animation-duration: 32098ms;
  animation-delay: 24109ms;
}

@keyframes move-frames-181 {
  from {
    transform: translate3d(16vw, 107vh, 0);
  }

  to {
    transform: translate3d(71vw, -116vh, 0);
  }
}

.circle-container2:nth-child(181) .circle {
  animation-delay: 215ms;
}

.circle-container2:nth-child(182) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-182;
  animation-duration: 33521ms;
  animation-delay: 20413ms;
}

@keyframes move-frames-182 {
  from {
    transform: translate3d(1vw, 106vh, 0);
  }

  to {
    transform: translate3d(17vw, -136vh, 0);
  }
}

.circle-container2:nth-child(182) .circle {
  animation-delay: 2956ms;
}

.circle-container2:nth-child(183) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-183;
  animation-duration: 35438ms;
  animation-delay: 29444ms;
}

@keyframes move-frames-183 {
  from {
    transform: translate3d(20vw, 102vh, 0);
  }

  to {
    transform: translate3d(35vw, -126vh, 0);
  }
}

.circle-container2:nth-child(183) .circle {
  animation-delay: 3321ms;
}

.circle-container2:nth-child(184) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-184;
  animation-duration: 34660ms;
  animation-delay: 22744ms;
}

@keyframes move-frames-184 {
  from {
    transform: translate3d(12vw, 103vh, 0);
  }

  to {
    transform: translate3d(10vw, -121vh, 0);
  }
}

.circle-container2:nth-child(184) .circle {
  animation-delay: 3626ms;
}

.circle-container2:nth-child(185) {
  width: 1px;
  height: 1px;
  animation-name: move-frames-185;
  animation-duration: 33549ms;
  animation-delay: 17214ms;
}

@keyframes move-frames-185 {
  from {
    transform: translate3d(3vw, 109vh, 0);
  }

  to {
    transform: translate3d(97vw, -111vh, 0);
  }
}

.circle-container2:nth-child(185) .circle {
  animation-delay: 3664ms;
}

.circle-container2:nth-child(186) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-186;
  animation-duration: 30535ms;
  animation-delay: 32724ms;
}

@keyframes move-frames-186 {
  from {
    transform: translate3d(78vw, 103vh, 0);
  }

  to {
    transform: translate3d(75vw, -115vh, 0);
  }
}

.circle-container2:nth-child(186) .circle {
  animation-delay: 3761ms;
}

.circle-container2:nth-child(187) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-187;
  animation-duration: 35438ms;
  animation-delay: 18335ms;
}

@keyframes move-frames-187 {
  from {
    transform: translate3d(45vw, 104vh, 0);
  }

  to {
    transform: translate3d(99vw, -120vh, 0);
  }
}

.circle-container2:nth-child(187) .circle {
  animation-delay: 477ms;
}

.circle-container2:nth-child(188) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-188;
  animation-duration: 33244ms;
  animation-delay: 6241ms;
}

@keyframes move-frames-188 {
  from {
    transform: translate3d(17vw, 107vh, 0);
  }

  to {
    transform: translate3d(22vw, -126vh, 0);
  }
}

.circle-container2:nth-child(188) .circle {
  animation-delay: 899ms;
}

.circle-container2:nth-child(189) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-189;
  animation-duration: 34146ms;
  animation-delay: 22185ms;
}

@keyframes move-frames-189 {
  from {
    transform: translate3d(96vw, 105vh, 0);
  }

  to {
    transform: translate3d(54vw, -129vh, 0);
  }
}

.circle-container2:nth-child(189) .circle {
  animation-delay: 1636ms;
}

.circle-container2:nth-child(190) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-190;
  animation-duration: 31554ms;
  animation-delay: 3633ms;
}

@keyframes move-frames-190 {
  from {
    transform: translate3d(58vw, 105vh, 0);
  }

  to {
    transform: translate3d(31vw, -133vh, 0);
  }
}

.circle-container2:nth-child(190) .circle {
  animation-delay: 1078ms;
}

.circle-container2:nth-child(191) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-191;
  animation-duration: 28930ms;
  animation-delay: 26467ms;
}

@keyframes move-frames-191 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(62vw, -108vh, 0);
  }
}

.circle-container2:nth-child(191) .circle {
  animation-delay: 3548ms;
}

.circle-container2:nth-child(192) {
  width: 4px;
  height: 4px;
  animation-name: move-frames-192;
  animation-duration: 28483ms;
  animation-delay: 34660ms;
}

@keyframes move-frames-192 {
  from {
    transform: translate3d(85vw, 103vh, 0);
  }

  to {
    transform: translate3d(54vw, -121vh, 0);
  }
}

.circle-container2:nth-child(192) .circle {
  animation-delay: 440ms;
}

.circle-container2:nth-child(193) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-193;
  animation-duration: 32543ms;
  animation-delay: 10492ms;
}

@keyframes move-frames-193 {
  from {
    transform: translate3d(59vw, 104vh, 0);
  }

  to {
    transform: translate3d(69vw, -131vh, 0);
  }
}

.circle-container2:nth-child(193) .circle {
  animation-delay: 829ms;
}

.circle-container2:nth-child(194) {
  width: 6px;
  height: 6px;
  animation-name: move-frames-194;
  animation-duration: 30645ms;
  animation-delay: 12380ms;
}

@keyframes move-frames-194 {
  from {
    transform: translate3d(12vw, 110vh, 0);
  }

  to {
    transform: translate3d(45vw, -127vh, 0);
  }
}

.circle-container2:nth-child(194) .circle {
  animation-delay: 1113ms;
}

.circle-container2:nth-child(195) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-195;
  animation-duration: 34270ms;
  animation-delay: 15876ms;
}

@keyframes move-frames-195 {
  from {
    transform: translate3d(50vw, 101vh, 0);
  }

  to {
    transform: translate3d(87vw, -118vh, 0);
  }
}

.circle-container2:nth-child(195) .circle {
  animation-delay: 1624ms;
}

.circle-container2:nth-child(196) {
  width: 7px;
  height: 7px;
  animation-name: move-frames-196;
  animation-duration: 29392ms;
  animation-delay: 25816ms;
}

@keyframes move-frames-196 {
  from {
    transform: translate3d(42vw, 102vh, 0);
  }

  to {
    transform: translate3d(63vw, -107vh, 0);
  }
}

.circle-container2:nth-child(196) .circle {
  animation-delay: 1477ms;
}

.circle-container2:nth-child(197) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-197;
  animation-duration: 36800ms;
  animation-delay: 5377ms;
}

@keyframes move-frames-197 {
  from {
    transform: translate3d(79vw, 101vh, 0);
  }

  to {
    transform: translate3d(82vw, -130vh, 0);
  }
}

.circle-container2:nth-child(197) .circle {
  animation-delay: 3276ms;
}

.circle-container2:nth-child(198) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-198;
  animation-duration: 28760ms;
  animation-delay: 21968ms;
}

@keyframes move-frames-198 {
  from {
    transform: translate3d(16vw, 106vh, 0);
  }

  to {
    transform: translate3d(5vw, -126vh, 0);
  }
}

.circle-container2:nth-child(198) .circle {
  animation-delay: 2084ms;
}

.circle-container2:nth-child(199) {
  width: 2px;
  height: 2px;
  animation-name: move-frames-199;
  animation-duration: 33994ms;
  animation-delay: 7886ms;
}

@keyframes move-frames-199 {
  from {
    transform: translate3d(72vw, 106vh, 0);
  }

  to {
    transform: translate3d(25vw, -123vh, 0);
  }
}

.circle-container2:nth-child(199) .circle {
  animation-delay: 367ms;
}

.circle-container2:nth-child(200) {
  width: 8px;
  height: 8px;
  animation-name: move-frames-200;
  animation-duration: 32701ms;
  animation-delay: 18293ms;
}

@keyframes move-frames-200 {
  from {
    transform: translate3d(61vw, 103vh, 0);
  }

  to {
    transform: translate3d(24vw, -110vh, 0);
  }
}

.circle-container2:nth-child(200) .circle {
  animation-delay: 3411ms;
}

